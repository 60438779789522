import { useState } from "react";
import logo from "../../assets/images/_logo.png";
import { Spin } from "../loader";
import axios from "axios";
import { proxy } from "../../endpoints";
import { Feedback } from "../modals/feedback";
import { Link } from "react-router-dom";
import { dbName, senderID } from "../../assets/data/states";

export const CheckStaff = ({ nextNode }) => {
  const [id, setID] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    message: "",
    type: "error",
    open: false,
  });

  const checkID = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.get(`${proxy}/staff/${id}`, {
        headers: {
          dbName,
        },
      });
      const { is_active, phone } = data;
      if (is_active === false) {
        setResponse({
          open: true,
          type: "error",
          message: `ID:${data.staff_id} has been disabled`,
        });
        setLoading(false);
      } else {
        // generate random number and send message.
        const min = 1000;
        const max = 9999;

        let code = Math.floor(Math.random() * (max - min + 1)) + min

        const info = {
          id: new Date().getTime().toString(),
          user_id: data.staff_id,
          code,
          phone,
          time_sent: new Date().toISOString(),
          has_expired: false,
          email: data.email,
        };

        try {
          // save details and send message.
          axios
            .post(`${proxy}/reset/`, { ...info }, { headers: { dbName } })
            .then(() => {
              axios
                .post(`${proxy}/messages/send-sms`, {
                  message: `${code} is your password reset token. Please ignore this message if you didn't initiate this request. Don't share this token`,
                  sender_id: senderID,
                  target: [phone],
                })
                .then(() => {
                  localStorage.setItem("STAFF_ID", data.staff_id);
                  setLoading(false);
                  setTimeout(() => nextNode(1), 3000);
                });
            });
        } catch (err) {
          setResponse({
            open: true,
            type: "error",
            message: `ID:${data.staff_id} has been disabled`,
          });
        }
      }
    } catch (err) {
      const { status } = err.response;
      setResponse({
        open: true,
        type: "error",
        message:
          status === 404
            ? `Staff ID (${id.toUpperCase()}) not found`
            : err.message,
      });
      setLoading(false);
    }

    // setTimeout(() => nextNode(1), 2000)
  };

  return (
    <form
      action="#"
      onSubmit={checkID}
      className="min-h-80 w-80 p-5 flex flex-col justify-center relative"
    >
      <div className="flex flex-col text-center items-center">
        <img
          src={logo}
          alt={"Logic Kids Logo"}
          className="w-20 h-20 object-contain"
        />
        <h3 className="text-xl">Reset Password</h3>
        <p className="text-sm">Verify Staff ID</p>
      </div>
      <div className="flex flex-col gap-2 my-3">
        <label htmlFor="email">Staff ID</label>
        <input
          required
          placeholder="Enter Staff ID"
          onChange={(e) => setID(e.target.value)}
          type="text"
          className="p-2 bg-gray-200"
          value={id}
        />
      </div>
      <button
        disabled={loading}
        className="p-2 bg-blue-500 mt-5 w-fit text-sm flex items-center justify-center  text-white"
      >
        {loading ? <Spin /> : "Send Code"}
      </button>

      <Link
        to="/start"
        className="mt-10 text-sm text-blue-500"
      >
        I already have an account
      </Link>

      {response.open && (
        <Feedback
          message={response.message}
          type={response.type}
          setOpen={() => setResponse({ open: false })}
        />
      )}
    </form>
  );
};
