import { signUserOut } from "../endpoints/firebase/auth";
import { useNavigate } from "react-router-dom";

export const PortalLocked = ({ logo, banner }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{ backgroundImage: `url(${banner})` }}
      className="flex-1 h-screen flex items-center bg-cover justify-center md:p-20 backdrop-blur-sm"
    >
      <div className="w-[80%] md:w-1/3 h-96 rounded-lg flex items-center justify-center flex-col bg-red-500/100">
        <div className="relative">
          <img
            src="https://cdn1.iconfinder.com/data/icons/bam-free-3d/128/Lock-locked.png"
            alt="Locked"
            className="w-48 h-48"
          />
          <img
            src={logo}
            alt="School"
            className="absolute bottom-4 right-4 w-20 h-20 object-contain"
          />
        </div>
        <h3 className="text-white text-3xl leading-relaxed">Sorry...</h3>
        <p className="text-sm text-gray-300 leading-loose">
          Portal has been Locked by Administrator
        </p>
        <div className="flex w-[90%] p-2 flex-col gap-2 md:flex-row mt-5">
          <button
            className="p-2 w-full md:w-1/2 rounded-sm text-sm bg-blue-50"
            onClick={() => window.location.reload()}
          >
            Refresh
          </button>
          <button
            className="p-2 w-full md:w-1/2 rounded-sm text-sm bg-red-50"
            onClick={() => {
              signUserOut().then(() => {
                localStorage.clear();
                navigate("/start");
              });
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};
