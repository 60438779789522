import { SideLink } from "../components";
import logo from "../assets/images/_logo.png";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { useState } from "react";

import { routes } from "./routes";

export const Sidebar = ({ school, close }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <div
      className={`flex border-r border-r-gray-300  flex-col ${
        toggle ? "slide-in z-[50]" : "slide-out "
      } h-screen bg-white p-3 relative`}
    >
      <div className="mt-2 mb-6 p-2 gap-1 flex shrink-0 items-center">
        <img
          src={school?.logo || logo}
          alt="School Logo"
          className="w-8 h-8 rounded-sm object-contain"
        />{" "}
        <h3
          className={`${toggle ? "hidden" : "inline-block truncate text-md"}`}
        >
          {school?.name}
          <p className="text-xs -pt-2">Logic Schoolx</p>
        </h3>
      </div>
      <div
        className={`${
          toggle ? "space-y-6" : "space-y-8 "
        } p-2 overflow-y-scroll`}
      >
        {routes(toggle).map((info, idx) => (
          <SideLink {...info} key={idx} close={close}/>
        ))}
      </div>
      <button
        onClick={() => setToggle(!toggle)}
        className="absolute block bottom-5 right-[-10px] z-50"
      >
        {toggle ? (
          <BsArrowRightCircleFill className="text-gray-700" />
        ) : (
          <BsArrowLeftCircleFill className="text-gray-700" />
        )}
      </button>
    </div>
  );
};
