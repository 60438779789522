import { Navigate } from "react-router-dom";
import Layout from "../layout";
import { Suspense } from "react";
import { Loading } from "../components/loader";

export const Private = ({ Child, title }) => {
  const authenticated = localStorage.getItem("STAFFLOGGED");
  
  return authenticated ? (
    <Suspense fallback={<Loading />}>
      <Layout Child={Child} title={title} />
    </Suspense>
  ) : (
    <Navigate to="/start" replace />
  );
};
