import { useEffect, useState } from "react";
import _ from "lodash";
import { tracks, table_tracks,  } from "../../assets/data/tracks";
import { StudentExamTile } from "../tiles";
import { BsArrowDownRightCircle } from 'react-icons/bs'
import { getAttendance } from "./attendance";
import { readDocuments, updateData, uploadData } from "../../endpoints";
import { Feedback } from "../modals/feedback";
import { Spin } from "../loader";
import { v4 as idv4 } from "uuid";
import { progress_remarks } from "../../assets/data/remarks";


export default function ProgressReport({ settings, students, profile }) {
  const [report, setReport] = useState([]);
  const [clock, setClock] = useState(1)
  const [update, setUpdate] = useState({ status: false, id: '' })
  const [adds, setAdds] = useState({ attendance: 0, teachers_remarks: "" })
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(false);
  const [attendance, setAttendance] = useState(0);
  const [response, setResponse] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [student, setStudent] = useState(null);
  const [show, setShow] = useState(false);
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    readDocuments({
      path: `/progress/${profile?.class_assigned}?academic_year=${settings.academicYear}&clock=${clock}`,
      getData: (data) => setReports(data || [])
    })
  }, [profile, settings, clock])



  const checkField = (category, event) => {

    const temp = report;
    // find if category is in temp.
    const cat = _.find(temp, (tp) => tp.category === category);

    // new category, add new remarks
    if (cat === undefined) {
      temp.push({
        category,
        data: [{ description: event.target.name, remarks: event.target.value }],
      });
      setReport(temp);
      return;
    }

    // already exists
    if (category !== undefined) {
      // find if that description exists
      let tagged = cat.data;
      const desc = _.find(tagged, (dt) => dt.description === event.target.name);

      // if desc exists, replace it's remarks
      if (desc) {
        tagged[tagged.indexOf(desc)].remarks = event.target.value;
        temp[temp.indexOf(cat)].data = tagged;
        setReport(prev => temp);
      } else {
        tagged.push({
          description: event.target.name,
          remarks: event.target.value,
        });
        // add this to report.
        temp[temp.indexOf(cat)].data = tagged;
        setReport(prev => temp);
      }
      return;
    }
  };

  const addReport = async (e) => {
    e.preventDefault();
    setLoading(true)

    let info = {

      student_id: student?.student_id,
      name: student.last_name + " " + student.other_names,
      photo: student.photo,
      division: student?.division,
      class: profile?.class_assigned,
      term: settings.currentTerm,
      category: student.class_category,
      academic_year: settings.academicYear,
      year: new Date().getFullYear(),
      records: report,
      attendance: adds?.attendance || attendance,
      teachers_remarks: adds?.teachers_remarks || "",
      signature: profile.staff_id,
      phone: student.contact_1 || student.contact_2,
      total_days: parseInt(settings.numberOfWeeks * 5) - settings.numberOfHolidays,
      current_class: student?.current_class,
    };


    try {

      if (update.status === true) {
    
        updateData({ path: `/progress`, id: update.id, data: info }).then(() => {
          setResponse({
            open: true,
            type: "success",
            message: "Progress report updated successfully!",
          });
        }).catch(err => {
          setResponse({
            open: true,
            type: "error",
            message: err.message || "Error updating progress report!",
          });
        })
        setClock(new Date().getSeconds());
        setReport(report)
        return
      }

      const { data, status } = await uploadData({
        path: "/progress/",
        data: { id: idv4().slice(0, 29).replaceAll("-", ""), ...info },
      });


      if ([201, 200, 202].includes(status)) {
        setClock(new Date().getSeconds());
        setResponse({
          open: true,
          type: "success",
          message: data.message,
        });
      }


    } catch (err) {
      const { message } = err.response?.data
      setResponse({ open: true, message: message || err.message, type: "error" })
    } finally {
      setLoading(false)
      setReport(report)
      setUpdate({ status: false, id: '' })
    }



  };

  const isProcessed = (id) => {
    return Boolean(reports.find((r) => r.student_id === id && r.term === settings.currentTerm))
  };

  const processUpdate = (id) => {
    let temp = reports.find((r) => r.student_id === id && r.term === settings.currentTerm)
    setReport(prev => temp?.records)
    setAdds({ teachers_remarks: temp.teachers_remarks, attendance: temp.attendance })
    if (temp !== undefined) {
      setUpdate({ status: true, id: temp.id })
      findStudent(id)
    }

    return

  };

  const findStudent = async (id) => {
    let student = _.find(students, (st) => st.student_id === id);
    setStudent(student);

    const attendance = await getAttendance(
      student?.student_id,
      settings.currentTerm,
      settings.academicYear
    );

    setAttendance(attendance);

  };

  const findTracks = () => {
    return tracks.filter((tr) => tr.group.includes(profile.class_assigned));
  }

  const findTableTracks = () => {
    return table_tracks.find((tr) => tr.group.includes(profile.class_assigned))?.initials || []
  }

  
  /* const isChecked = (category, name) => {
    if (update.status) {
      const result = report.find(rp => rp.category === category)?.data
      return result?.find((dt) => dt.description === name)?.remarks
    }

    return
  }*/


  return (
    <div className="flex flex-col md:flex-row w-full h-full p-1">
      {localStorage.getItem("isAdmin") !== null && (
        <button
          onClick={() => setShow(!show)}
          type="button"
          className="fixed text-sm bg-red-500 text-white p-2 rounded-md bottom-5 right-5 z-50"
        >
          Add Remarks
        </button>
      )}
      {show && (
        <div className="w-72 fixed bottom-10 right-10 z-50">
          <textarea
            placeholder="Head Teacher Remarks"
            className="resize-none p-4 border border-gray-500 rounded-md"
            cols="30"
            rows="5"
          ></textarea>
          <button className="px-3 py-1 bg-orange-500 rounded-sm text-sm">
            Update
          </button>
        </div>
      )}

      <div className="hidden md:block md:w-1/4 h-full bg-white  overflow-y-scroll">
        <div className="p-3 space-y-2">
          {students?.map((std, idx) => (
            <StudentExamTile
              student={std}
              student_id={student?.student_id}
              processed={isProcessed(std.student_id)}
              key={idx}
              select={findStudent}
              processUpdate={processUpdate}
            />
          ))}
        </div>
      </div>

      <div className="md:hidden w-[90vw] block relative p-2">
        <button
          className="w-full p-3 bg-white flex items-center justify-between"
          onClick={() => setDisplay(!display)}
        >
          <p>Select Student</p> <BsArrowDownRightCircle />
        </button>

        {display && (
          <div className="absolute w-full z-50 flex flex-col p-4 gap-2 h-48 overflow-y-auto bg-white shadow-md top-12">
            {students?.map((std, idx) => (
              <StudentExamTile
                student={std}
                student_id={student?.student_id}
                processed={isProcessed(std.student_id)}
                key={idx}
                select={findStudent}
                processUpdate={processUpdate}
              />
            ))}
          </div>
        )}
      </div>
      <form
        onSubmit={addReport}
        className="relative w-full h-[90vh] md:w-[70%] overflow-y-auto"
      >
        <div className="p-3 sticky top-1 bg-white z-40">
          <h3 className="text-xl">{`${student?.last_name || "No Student"} ${student?.other_names || "Selected"}`}</h3>
          <p className="text-sm">Progress Report</p>
        </div>
        <table className="w-full table-auto">
          <thead className="sticky top-0 bg-white">
            <tr className="text-left text-sm">
              <th className="p-3">Category</th>
              {findTableTracks().map(rm => (
                <th className="p-3">{rm}</th>
              ))}

            </tr>
          </thead>
          {findTracks().map((info) => (
            <tbody>
              <tr>
                <td colSpan={3} className="p-3 font-semibold">{info.category}</td>
              </tr>
              {info.fields.map((field, idx) => (
                <tr className="border-y text-sm" key={idx}>
                  <td className="p-3 ">{field.name}</td>
                  {
                    info.remarks.map((rm) => (
                      <td className="p-3" key={rm}>
                        <input
                          title={rm}
                          onClick={(e) => checkField(info.category, e)}
                          
                          type="radio"
                          className="w-4 h-4 cursor-pointer  checked:accent-gray-500"
                          name={field.name}
                          value={rm}
                        />
                      </td>
                    ))
                  }

                </tr>
              ))}
            </tbody>
          ))}
        </table>
        {student !== null && <div className="flex flex-col md:flex-row items-center mt-3 gap-2">
          <select
          className="p-2 border w-full md:w-2/3"
          onChange={(e) => setAdds({ ...adds, teachers_remarks: e.target.value })}
          value={adds.teachers_remarks} name="teachers_remarks" id="teachers_remarks">
            
            {progress_remarks(student?.other_names).map(info => <option key={info} value={info}>{info}</option>)}
          </select>
          <input value={adds.attendance} onChange={(e) => setAdds({ ...adds, attendance: parseInt(e.target.value) })} className="p-2 border flex-1" type="number" min={0} placeholder="Attendance" />
        </div>}
        <button
          disabled={!student}
          type="submit"
          className={`px-3 text-sm text-white py-2 ${update.status ? 'bg-green-600' : 'bg-orange-500'} mt-3`}
        >
          {loading ? <Spin /> : "Update Report"}
        </button>
      </form>
      {response.open && (
        <Feedback setOpen={() => setResponse({ open: false })} {...response} />
      )}
    </div>
  );
}
