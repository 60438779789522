import { header } from "../assets/images/data-uri/exams";
import pdfMake from "pdfmake"
import fonts from "../assets/fonts";
pdfMake.vfs = fonts;

pdfMake.fonts = {
  Sen: {
    normal: 'Sen-Regular.ttf',
    bold: 'Sen-Bold.ttf',
    italics: 'Sen-Regular.ttf',
    bolditalics: 'Sen-ExtraBold.ttf'
  }
};

// male_holder,

export default function generate_report(report, method) {
  if (report.length > 1) {

    const docDefinition = {
      paperSize: "A4",
      defaultStyle:{
        font:"Sen"
      },
      fontSize:10,
      watermark: {
        text: "Greater Heights School",
        fontSize:20,
        fontFamily:"Sen-Regular.ttf",
        color: "lightgrey",
        opacity: 0.2,
        bold: true,
        italics: true,
      },
      background: {
        image: header,
        width: 595,
      },
      content:report
    }
    //
    if (method === "print") {
      // we will be back
      return pdfMake.createPdf(docDefinition).print();
    }
  } else {
    if (method === "print") {
      return pdfMake.createPdf(report).print();
    } else {
      return pdfMake
        .createPdf(report)
        .download(report.last_name + " " + report.other_names + "-" + report.term);
    }
  }
}
