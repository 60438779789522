import { TiTick, TiTimes } from "react-icons/ti";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { AttendanceTile } from "../components/tiles";
import { days, weeks } from "../assets/data/time";
import { uploadData, updateData, readDocuments } from "../endpoints";
import _ from "lodash";
import { Feedback } from "../components/modals/feedback";
import { maxDate } from "../functions/misc";

export default function Attendance({ settings, profile, students }) {
  const [attendance, setAttendance] = useState([]);
  const [marked, setMarked] = useState([]);
  const [time, setTime] = useState({
    date: new Date().toISOString(),
    week: "",
    day: new Date().toLocaleString("en", { weekday: "long" }),
  });
  const [searchDate, setSearchDate] = useState(new Date());
  const [call, shouldCall] = useState(false);
  const [response, setResponse] = useState({
    open: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    readDocuments({
      path: `/attendance/get-class/${profile.class_assigned}/${profile.division}?academic_year=${settings.academicYear}&term=${settings.currentTerm}&callID=${call}`,
      getData: setAttendance,
    });
  }, [call, settings, profile]);

  const markAll = (checked) => {
    let temp = [];
    if (checked) {
      for (const std of students) {
        temp.push({
          student_id: std.student_id,
          gender: std.gender,
          is_present: true,
        });
      }
      setMarked(temp);
    } else {
      setMarked([]);
    }
  };

  const checkDayStatus = (index, day, week) => {
    const _attendance = _.find(attendance, (atd) => {
      return (
        atd.day === day &&
        atd.week === week &&
        atd.class === profile?.class_assigned
      );
    });

    if (_attendance === undefined) {
      return false;
    } else {
      let _status = _.find(_attendance.data, (cs) => {
        return cs.student_id === index;
      });

      if (_status === undefined) {
        return <TiTimes className="text-xl text-red-600" />;
      } else if (_status.is_present === true) {
        return <TiTick className="text-xl text-green-600" />;
      } else {
        return <TiTimes className="text-xl text-red-600" />;
      }
    }
  };

  const getAttendance = (status, index, gender) => {
    const _status = _.find(
      marked,
      (std) => std.student_id === index && std.is_present !== status
    );

    if (_status === undefined) {
      setMarked([
        ...marked,
        {
          student_id: index,
          gender,
          is_present: status,
        },
      ]);
    } else {
      // remove existing and add new attendance record.
      let temp = marked;
      // eslint-disable-next-line
      const changed = _.find(temp, (std) => std.student_id === index);
      // changed.is_present = status;
      changed.is_present = status;

      _.remove(marked, (std) => std.student_id === index);
      setMarked([...marked, changed]);
    }
  };

  const isSelected = (id) => {
    const isHere = _.find(
      marked,
      (std) => std.student_id === id && std.is_present === true
    );

    return isHere === undefined ? false : true;
  };

  const reportToday = () => {
    const todays_report = {
      processed_by: profile.last_name + " " + profile.first_name,
      ...time,

      class: profile.class_assigned,
      division: profile.division,

      term: settings.currentTerm,
      academic_year: settings.academicYear,
      year: new Date().getFullYear(),
      data: marked,

      signature: profile.staff_id,
    };

    let _marked = _.find(attendance, (pd) => {
      return pd.day === time.day && pd.week === time.week;
    });
    if (_marked === undefined) {
      uploadData({
        path: "/attendance/add",
        data: todays_report,
      })
        .then(() => {
          setResponse({
            open: true,
            message: "Attendance marking successful!",
            type: "success",
          });
          shouldCall(new Date().getSeconds());
        })
        .catch((err) =>
          setResponse({
            open: true,
            message: err.message,
            type: "error",
          })
        );
    } else {
      updateData({
        path: "/attendance",
        id: _marked.id,
        data: todays_report,
      })
        .then(() => {
          setResponse({
            open: true,
            message: "Attendance update successful!",
            type: "success",
          });
          shouldCall(new Date().getSeconds());
        })
        .catch((err) =>
          setResponse({
            open: true,
            message: err.message,
            type: "error",
          })
        );
    }
  };

  const getHistory = (date) => {
    const data = _.find(
      attendance,
      (std) =>
        new Date(std.date).toLocaleDateString() ===
        new Date(date).toLocaleDateString()
    );

    const stats = data !== undefined ? data.data : [];
    const boys = _.filter(
      stats,
      (dt) => dt.gender === "Male" && dt.is_present === true
    ).length;
    const girls = _.filter(
      stats,
      (dt) => dt.gender === "Female" && dt.is_present === true
    ).length;

    return [boys + girls , boys, girls];
  };

  const isToday =
    new Date(time?.date.slice(0, 10)).toLocaleString("en", {
      weekday: "long",
    }) === time?.day && !["Sunday", "Saturday"].includes(time?.day)

  return (
    <div className="flex flex-col md:flex-row flex-1 p-3 max-h-[90vh] overflow-hidden">
      <div className="w-full md:w-4/5 space-y-3 p-3 flex flex-col flex-1 overflow-hidden">
        <form className="flex flex-col md:flex-row sticky top-0 items-center md:space-y-0 space-y-2 md:space-x-2">
          <select
            value={time.day}
            onChange={(e) => setTime({ ...time, day: e.target.value })}
            className="p-2 bg-white w-full md:w-1/3"
          >
            <option value="">Select Day</option>
            {days.map((day) => (
              <option value={day} key={day}>
                {day}
              </option>
            ))}
          </select>
          <select
            value={time.week}
            onChange={(e) => setTime({ ...time, week: e.target.value })}
            className="p-2 bg-white w-full md:w-1/3"
          >
            <option value="">Select Week</option>
            {weeks.map((week) => (
              <option value={week} key={week}>
                {week}
              </option>
            ))}
          </select>
          <input
            type="date"
            required={!isToday}
            value={time.date?.slice(0, 10)}
            max={maxDate()}
            onChange={(e) =>
              setTime({
                ...time,
                date: new Date(e.target.value).toISOString(),
                day: new Date(e.target.value).toLocaleString("en", {
                  weekday: "long",
                }),
              })
            }
            className="p-1 w-full md:w-1/3 bg-white border"
          />
        </form>
        <div className="hidden md:block overflow-scroll w-full">
          <table className="text-xs md:text-sm table-fixed w-full bg-white">
            <thead className="sticky top-0 bg-white">
              <tr className="border-b font-medium">
                <td rowSpan={2} colSpan={2} className="p-3">
                  Students
                </td>
                <td colSpan={2} className="p-3 truncate">
                  {time.week ? time.week : "No Week Selected"}
                </td>
                <td colSpan={2}>Total : {marked.length}</td>
                <td colSpan={1}>
                  <div className="flex items-center space-x-2">
                    <input
                      checked={marked.length === students.length}
                      onChange={(e) => markAll(e.target.checked)}
                      type="checkbox"
                      id="selectAll"
                      className="w-4 h-4"
                    />{" "}
                    <label htmlFor="selectAll" className="truncate">
                      Select All
                    </label>
                  </div>
                </td>
                <td>
                  <button
                    onClick={() => reportToday()}
                    disabled={
                      !time.week || !time.day || marked.length < 1 || !isToday
                    }
                    className="bg-green-600 text-white text-xs p-2 whitespace-nowrap rounded-sm"
                  >
                    Mark Attendance
                  </button>
                </td>
              </tr>
              <tr className="font-bold border text-sm text-gray-400 ">
                <td className="p-3">MON</td>
                <td className="p-3">TUE</td>
                <td className="p-3">WED</td>
                <td className="p-3">THU</td>
                <td className="p-3">FRI</td>
                <td className="p-3 truncate">MARK</td>
              </tr>
            </thead>
            <tbody>
              {students?.map((std, idx) => (
                <tr className="text-sm border border-b" key={idx}>
                  <td className="p-3 border truncate" colSpan={2}>
                    {std.last_name + " " + std.other_names}
                  </td>
                  <td className="p-2 border">
                    {checkDayStatus(std.student_id, "Monday", time.week)}
                  </td>
                  <td className="p-2 border">
                    {checkDayStatus(std.student_id, "Tuesday", time.week)}
                  </td>
                  <td className="p-3 border">
                    {checkDayStatus(std.student_id, "Wednesday", time.week)}
                  </td>
                  <td className="p-3 border">
                    {checkDayStatus(std.student_id, "Thursday", time.week)}
                  </td>
                  <td className="p-3 border">
                    {checkDayStatus(std.student_id, "Friday", time.week)}
                  </td>

                  <td className="p-3">
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          getAttendance(
                            e.target.checked,
                            std.student_id,
                            std.gender
                          )
                        }
                        checked={isSelected(std.student_id)}
                        className="w-4 h-4"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="block overflow-y-auto md:hidden">
          <table className="table-auto w-full text-sm bg-white">
            <thead>
              <tr>
                <th className="text-left p-3">Students</th>
                <th className="flex justify-end text-right p-3">
                  <div className="flex items-center space-x-2">
                    <input
                      checked={marked.length === students.length}
                      onChange={(e) => markAll(e.target.checked)}
                      type="checkbox"
                      id="selectAll"
                      className="w-4 h-4"
                    />{" "}
                    <label htmlFor="selectAll" className="truncate">
                      Select All
                    </label>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {students?.map((std, idx) => (
                <tr className="text-sm border border-b" key={idx}>
                  <td className="p-2 border truncate" colSpan={2}>
                    {std.last_name + " " + std.other_names}
                  </td>
                  <td className="p-3">
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          getAttendance(
                            e.target.checked,
                            std.student_id,
                            std.gender
                          )
                        }
                        checked={isSelected(std.student_id)}
                        className="w-4 h-4"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2} className="p-3">
                  <button
                    onClick={() => reportToday()}
                    disabled={!time.week || !time.day || marked.length < 1}
                    className="bg-green-600 text-white text-xs p-2 whitespace-nowrap rounded-sm"
                  >
                    Mark Attendance
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div className="w-full hidden md:block md:w-1/4 bg-white p-3 ml-1 space-y-5">
        <h3>Get Attendance History</h3>
        <div className="text-xs flex items-center flex-col">
          <Calendar
            className={"border-none"}
            maxDate={new Date()}
            minDetail="month"
            prev2Label={null}
            next2Label={null}
            value={time.date}
            onChange={(v, event) => setSearchDate(v)}
          />

          {/*  */}
          <AttendanceTile history={getHistory(searchDate)} />
        </div>
        {response.open && (
          <Feedback
            {...response}
            setOpen={() => setResponse({ open: false })}
          />
        )}
      </div>
    </div>
  );
}
