import { VscBook } from "react-icons/vsc";
import { GoTrash } from "react-icons/go";
import { LuFileEdit } from "react-icons/lu";
import { FcCalendar } from "react-icons/fc";
import Editor from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import { deleteData, readDocument, updateData, uploadData } from "../endpoints";
import { Feedback } from "../components/modals/feedback";
import { _courses } from "../assets/data/courses";
import { checkFile } from "../functions";
import { deleteFile, uploadFile } from "../endpoints/fb";
import { Spin } from "../components/loader";

export default function Assignments({ profile, settings }) {
  const [subject, setSubject] = useState("");
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState(null);
  const [file, setFile] = useState(null)
  const [edit, setEdit] = useState({
    editMode: false,
    id: "",
  });
  const [call, shouldCall] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [response, setResponse] = useState({
    open: false,
    type: "",
    message: "",
  });

  // console.log(questions)

  useEffect(() => {
    readDocument({
      path: "/assignment",
      id: `${profile?.class_assigned}/${profile.division}?term=${settings.currentTerm}&academic_year=${settings.academicYear}&call=${call}`,
      getData: setQuestions,
    });
  }, [profile, settings, call]);

  const sendAssignment = () => {
    console.log(file)
    const data = {
      subject,
      question,
      class: profile?.class_assigned,
      division: profile?.division,
      term: settings?.currentTerm,
      file: file || null,
      academic_year: settings?.academicYear,
      posted_by: profile?.first_name + " " + profile?.last_name,
    };

    if (edit.editMode) {
      updateData({
        path: "/assignment",
        id: edit?.id,
        data,
      })
        .then(() => {
          setResponse({
            open: true,
            type: "success",
            message: "Assignment Successfully Posted",
          });
          setEdit({
            editMode: false
          })
          setFile(null)
          setTimeout(() => setQuestion(null), 500);
          shouldCall(new Date().getSeconds());
        })
        .catch((err) => {
          setResponse({
            open: true,
            type: "error",
            message: err.message,
          });
        });
    } else {
      uploadData({
        path: "/assignment/",
        data,
      })
        .then(() => {
          setResponse({
            open: true,
            type: "success",
            message: "Assignment Successfully Posted",
          });
          setEdit({
            editMode: false
          })
          setTimeout(() => setQuestion(null), 500);
          shouldCall(new Date().getSeconds());
        })
        .catch((err) => {
          setResponse({
            open: true,
            type: "error",
            message: err.message,
          });
        });
    }

  };

  const editQuestion = (q) => {
    setEdit({
      editMode: true,
      id: q.id,
    });
    setSubject(q.subject);
    setQuestion(q.question);
  };

  const deleteQuestion = (id, file) => {
    deleteData({
      path: "/assignment",
      id,
    })
      .then(() => {

        if (file) {
          deleteFile(file)
        }

        setResponse({
          open: true,
          message: "Assignment Successfully Deleted",
          type: "success",
        });

        shouldCall(new Date().getSeconds());
      })
      .catch((err) =>
        setResponse({
          open: true,
          message: err.message,
          type: "error",
        })
      );


  };

  const triggerUpdate = e => {
    setLoading(true)
    if (e.target.files[0]) {
      if (checkFile(e.target.files[0])) {
        // upload and get url
        uploadFile({
          path: `/assignment/${profile?.class_assigned}/${new Date().getFullYear()}/${new Date().getTime()}`,
          file: e.target.files[0],
          getLink: (link) => {
            setFile(link)
            setResponse({ open: true, message: "File Upload Successfully", type: "success" })
          },
        })

        setLoading(false)
        return
      }
    }

    setResponse({ open: true, message: "Attempted to upload invalid file", type: 'error' })
    setLoading(false)
  }

  return (
    <div className="flex flex-col md:flex-row flex-1 p-3 max-h-[90vh] overflow-hidde">
      <div className="w-full md:w-2/3  p-2 md:p-5  h-2/3 md:h-full bg-gray-100">
        <select
          onChange={(e) => setSubject(e.target.value)}
          className="w-56 p-2 shadow-md my-3 border-none"
        >
          <option value="">Select Subject</option>
          {_courses.map((crs, idx) => (
            <option value={crs.value} key={idx}>
              {crs.text}
            </option>
          ))}
        </select>
        <Editor
          value={question}
          onChange={setQuestion}
          placeholder="Enter Questions Here"
          theme="snow"
          className=" w-[90%] text-lg bg-white  h-72 md:h-80  mx-auto"
        />
        <div className="flex gap-2 items-center md:ml-10 md:mt-14">
          <label htmlFor="attachment" className="text-sm bg-blue-500 text-white p-2 cursor-pointer">{file ? "Image Uploaded" : loading ? <Spin /> : "Attach PDF or Image"}</label>
          <input readOnly={!subject || question === null} type="file" onChange={triggerUpdate} name="attachment" id="attachment" className="hidden" />

          <button
            disabled={!subject || question === null}
            onClick={sendAssignment}
            className="p-2 bg-yellow-500 text-sm  font-medium rounded-sm shrink-0"
          >
            {edit.editMode ? "Update Question" : "Submit"}
          </button>
        </div>

      </div>
      <div className="flex-1 flex flex-col bg-gray-200/30 p-5 overflow-y-auto h-[94vh]">
        <h1 className="p-2 font-bold text-lg">Posted Assignments</h1>

        {(questions?.length && (
          <div className="flex flex-col gap-3">
            {questions?.map((q, idx) => (
              <div
                className="flex flex-col w-full p-3 bg-white shadow-md rounded-md"
                key={idx}
              >
                <div className="flex items-center justify-between">
                  <span className="flex items-center gap-2">
                    {" "}
                    <VscBook className="text-gray-600" /> <p>{q.subject}</p>
                  </span>

                  <div className="flex gap-4 justify-end">
                    <button
                      title="Delete"
                      onClick={() =>
                        deleteQuestion(q.id, q.file)
                      }
                    >
                      <GoTrash className="text-red-500 text-lg" />
                    </button>
                    <button title="Edit" onClick={() => editQuestion(q)}>
                      <LuFileEdit className="text-blue-500 text-lg" />
                    </button>
                  </div>
                </div>

                <div
                  className="text-sm leading-relaxed my-2"
                  dangerouslySetInnerHTML={{ __html: q.question }}
                />

                <span className="flex items-center gap-2">
                  {" "}
                  <FcCalendar />{" "}
                  <p className="text-xs">
                    {new Date(q.createdAt).toLocaleString("ru")}
                  </p>

                  {q.file && <a href={q.file} target="_blank" rel="noreferrer" className="text-xs font-bold">View Attached File</a>}
                </span>
              </div>
            ))}
          </div>
        )) || (
            <div className="w-full flex flex-col items-center justify-center h-56">
              <div className="w-24 h-24 animate-pulse">
                <img src="https://cdn4.iconfinder.com/data/icons/education-862/135/Homework-256.png" alt="No Homework" />
              </div>
              <p className="text-xl text-red-500">no homeworks yet.</p>
            </div>
          )}
      </div>
      {response.open && (
        <Feedback setOpen={() => setResponse({ open: false })} {...response} />
      )}
    </div>
  );
}
