import { sortResults } from "./misc";
import _ from "lodash";

export const getThisClassReport = (exams, term, category, division) => {
  //get report for current_class for current_term
  var std_report = _.filter(exams, (crp) => {
    return crp.term === term && crp.division === division;
  });

  let 
    ups = {}

    
  let math_scores = [],
    eng_scores = [],
    sci_scores = [],
    fantse_scores = [],
    rme_scores = [],
    french_scores = [],
    ict_scores = [],
    owop_scores = [],
    history_scores = [],
    cr_scores = [],
    overrall_aggs = [],
    ph_scores = [],
    writ_scores = [], 
    ctech_scores = []

  if (std_report.length > 0) {
    //separation of courses by class_category
    //junior High Courses.
      _.map(std_report, (cp) => {
        math_scores.push(cp.mts);
        eng_scores.push(cp.ets);
        sci_scores.push(cp.sts);
        fantse_scores.push(cp.fnts);
        rme_scores.push(cp.rmts);
        french_scores.push(cp.fts);
        ph_scores.push(cp.phts);
        ict_scores.push(cp.ictts);
        owop_scores.push(cp.owts);
        cr_scores.push(cp.crats);
        history_scores.push(cp.hts);
        ctech_scores.push(cp.ctts); 
        overrall_aggs.push(cp.overrall_aggregate);
      });
  

      if (category === "Pre School") {
      _.map(std_report, (cp) => {
        math_scores.push(cp.mts);
        eng_scores.push(cp.ets);
        writ_scores.push(cp.wrts);
        owop_scores.push(cp.owts);
        cr_scores.push(cp.crats);
        ph_scores.push(cp.phts);
        overrall_aggs.push(cp.overrall_aggregate);
      });
    }


    ups = {
      maths: sortResults(math_scores),
      creative_arts: sortResults(cr_scores),
      science: sortResults(sci_scores),
      english: sortResults(eng_scores),
      rme: sortResults(rme_scores),
      french: sortResults(french_scores),
      fantse: sortResults(fantse_scores),
      ict: sortResults(ict_scores),
      owop: sortResults(owop_scores),
      history: sortResults(history_scores),
      pe: sortResults(ph_scores),
      ctech: sortResults(ctech_scores),
      overrall: sortResults(overrall_aggs),
    };

      return ups;

  }
};

export const getContacts = (students, type) => {
  const contacts = [];
  const allContacts = [];
  if (type === "SMS") {
    for (var std of students) {
      contacts.push({
        text: std.last_name + " " + std.other_names,
        value: std.contact_1,
      });
      allContacts.push(std.contact_1, std.contact_2);
    }
  } else {
    for (std of students) {
      contacts.push({
        text: std.last_name + " " + std.other_names,
        value: std.delivery_mail,
      });
      allContacts.push(std.delivery_mail);
    }
  }

  return [...contacts, { text: "All", value: _.uniq(allContacts) }];
};
