import { _courses } from "../assets/data/courses";
import { TableSummary } from "../components";
import { CourseGraph } from "../components/graph";
import { LongTile, Tile2, Tiles } from "../components/tiles";
import { useEffect, useState } from "react";
import { getGrades } from "../functions";
import {  readDocument } from "../endpoints";


export default function Dashboard({ settings, exams, students, profile }) {
  const [course, setCourse] = useState("N/A");
  const [currentSubject, setCurrentSubject] = useState({});
  const today = new Date().toLocaleDateString("en-GB", { weekday: "long" });
  const hour = 1 + (new Date().getHours() % 12);
  const [term, setTerm] = useState("First Term");



  useEffect(() => {
    async function readData() {
      readDocument({
        path: `/table/current-subject`,
        id: `${profile.class_assigned}/${profile.division}/${today}/${hour}`,
        getData: (data) => setCurrentSubject(data || {}),
      });
    }
    try {
      readData();
    } catch (err) {
      console.log(err);
    }
  }, [
    profile.class_assigned,
    settings.academicYear,
    hour,
    today,
    profile.division,
  ]);

  const results = getGrades(course, exams, term);

  return (
    <div className="relative flex-1 flex flex-col md:flex-row p-3 max-h-[95vh] overflow-hidden shrink-0">
      <div className="w-full md:w-3/4  p-3">
        {/* Top Tiles */}
        <div className="hidden  justify-around md:flex gap-2 flex-row">
          <LongTile students={students} color={'bg-[#00eed0]'}/>

          <Tiles info={"N/A"} description={"Number of Subjects"} color={'bg-[#409da5]'}/>
          <Tiles
            info={currentSubject?.subject || "N/A"}
            description={"Current Subject"}
            color={"bg-[#d41243]"}
          />

          <Tiles info={settings?.currentTerm} description={"Term"}  color={'bg-[#ffb900]'}/>
        </div>
        <div className="flex flex-col gap-2 md:hidden">
          <div className="flex gap-2">
            <Tile2 info={students.length} description={"Students"} />
            <Tile2
              info={currentSubject?.subject || "N/A"}
              description={"Current Subject"}
            />
          </div>
          <div className="flex gap-2">
            <Tile2
              info={settings?.academicYear}
              description={"Academic Year"}
            />

            <Tile2 info={settings?.currentTerm} description={"Term"} />
          </div>
        </div>
        {/* Exams Statistics */}
        <div className="hidden w-full md:flex flex-col md:flex-row bg-white mt-3 rounded-md p-3">
          <div className="w-3/4">
            {/* Exams Course Graph */}
            <CourseGraph _history={exams} settings={settings} />
          </div>
          <div className="w-full md:w-1/4 p-2">
            <form className="md:space-y-3">
              <select
                className="p-2"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
              >
                <option value="">Select Term</option>
                <option value="First Term">First Term</option>
                <option value="Second Term">Second Term</option>
                <option value="Third Term">Third Term</option>
              </select>
              <select
                value={course}
                className="p-2"
                onChange={(e) => setCourse(e.target.value)}
              >
                <option value="">Select Course</option>
                {_courses.map((crs, idx) => (
                  <option key={idx} value={crs.value}>
                    {crs.text}
                  </option>
                ))}
              </select>

              {course && (
                <table className="table-fixed w-full ">
                  <thead>
                    <tr className="text-xs font-medium border">
                      <td className="p-3 border text-center">Grade</td>
                      <td className="p-3 border text-center">Number</td>
                      <td className="p-3 border text-center">%</td>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    <tr className="text-sm border border-b-gray-300">
                      <td className="p-2 border font-bold">A</td>
                      <td className="p-2 border">{results.number[0]}</td>
                      <td className="p-2 border">{results.percentage[0]}</td>
                    </tr>
                    <tr className="text-sm border border-b-gray-300">
                      <td className="p-2 border font-bold">B</td>
                      <td className="p-2 border">{results.number[1]}</td>
                      <td className="p-2 border">{results.percentage[1]}</td>
                    </tr>
                    <tr className="text-sm border border-b-gray-300">
                      <td className="p-2 border font-bold">C</td>
                      <td className="p-2 border">{results.number[2]}</td>
                      <td className="p-2 border">{results.percentage[2]}</td>
                    </tr>
                    <tr className="text-sm border border-b-gray-300">
                      <td className="p-2 border font-bold">D</td>
                      <td className="p-2 border">{results.number[3]}</td>
                      <td className="p-2 border">{results.percentage[3]}</td>
                    </tr>
                    <tr className="text-sm border border-b-gray-300">
                      <td className="p-2 border font-bold">E</td>
                      <td className="p-2 border">{results.number[4]}</td>
                      <td className="p-2 border">{results.percentage[4]}</td>
                    </tr>
                    <tr className="text-sm border border-b-gray-300">
                      <td className="p-2 border font-bold">F</td>
                      <td className="p-2 border">{results.number[5]}</td>
                      <td className="p-2 border">{results.percentage[5]}</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </form>
          </div>
        </div>
      </div>
      <div className="w-full md:1/4  p-3 overflow-y-auto space-y-5">
        <TableSummary profile={profile} term={settings.currentTerm} /> 
      </div>
    </div>
  );
}
