export const composeMailMessage = (info) => {
    return `
    <!doctype html>
   <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
   
   <head>
     <title>
     </title>
     <!--[if !mso]><!-->
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
     <!--<![endif]-->
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
     <meta name="viewport" content="width=device-width, initial-scale=1">
     <style type="text/css">
     @import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');
       #outlook a {
         padding: 0;
       }
   
       body {
         margin: 0;
         padding: 0;
         -webkit-text-size-adjust: 100%;
         -ms-text-size-adjust: 100%;
       }
   
       table,
       td {
         border-collapse: collapse;
         mso-table-lspace: 0pt;
         mso-table-rspace: 0pt;
       }
   
       img {
         border: 0;
         height: auto;
         line-height: 100%;
         outline: none;
         text-decoration: none;
         -ms-interpolation-mode: bicubic;
       }
   
       p {
         display: block;
         margin: 13px 0;
       }
     </style>
     <!--[if mso]>
           <noscript>
           <xml>
           <o:OfficeDocumentSettings>
             <o:AllowPNG/>
             <o:PixelsPerInch>96</o:PixelsPerInch>
           </o:OfficeDocumentSettings>
           </xml>
           </noscript>
           <![endif]-->
     <!--[if lte mso 11]>
           <style type="text/css">
             .mj-outlook-group-fix { width:100% !important; }
           </style>
           <![endif]-->
     <style type="text/css">
       @media only screen and (min-width:480px) {
         .mj-column-per-100 {
           width: 100% !important;
           max-width: 100%;
         }
       }
     </style>
     <style media="screen and (min-width:480px)">
       .moz-text-html .mj-column-per-100 {
         width: 100% !important;
         max-width: 100%;
       }
     </style>
     <style type="text/css">
       @media only screen and (max-width:480px) {
         table.mj-full-width-mobile {
           width: 100% !important;
         }
   
         td.mj-full-width-mobile {
           width: auto !important;
         }
       }
     </style>
   </head>
   
   <body style="word-spacing:normal;">
     <div style="">
       <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
       <div style="margin:0px auto;max-width:600px;">
         <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
           <tbody>
             <tr>
               <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                 <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                 <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                   <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                     <tbody>
                       <tr>
                         <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                           <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                             <tbody>
                               <tr>
                                 <td style="width:70px;">
                                   <img height="auto" src="https://thelogickids.com/_.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="70" />
                                 </td>
                               </tr>
                             </tbody>
                           </table>
                         </td>
                       </tr>
                       <tr>
                         <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                           <p style="border-top:solid 4px #F45E43;font-size:1px;margin:0px auto;width:100%;">
                           </p>
                           <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 4px #F45E43;font-size:1px;margin:0px auto;width:550px;" role="presentation" width="550px" ><tr><td style="height:0;line-height:0;"> &nbsp;
   </td></tr></table><![endif]-->
                         </td>
                       </tr>
                     </tbody>
                   </table>
                 </div>
                 <!--[if mso | IE]></td></tr></table><![endif]-->
               </td>
             </tr>
           </tbody>
         </table>
       </div>
       <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
       <div style="margin:0px auto;max-width:600px;">
         <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
           <tbody>
             <tr>
               <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                 <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                 <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                   <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color:#202020;vertical-align:top;" width="100%">
                     <tbody>
                       <tr>
                         <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                           <div style="font-family:sen;font-size:15px;line-height:1;text-align:left;color:#F45E43;">Dear Sir/Ma'am,</div>
                         </td>
                       </tr>
                       <tr>
                         <td align="center" style="font-size:0px;padding:6px;word-break:break-word;">
                           <div style="font-family:sen;font-size:13px;line-height:17px;text-align:center;color:#ffffff;">We hereby with this message inform you that the examination report of our ward <span style="font-weight: bold;
           color:#ea6a47;">${info.last_name + " " + info.other_names}</span> of <span style="font-weight: bold;
           color:#ea6a47;">${info.term}</span> for <span style="font-weight: bold;color:#ea6a47;">${info.current_class}</span> is available. Please note that School will resume on <span style="font-weight: bold;
           color:#ea6a47;">${info.next_term}</span> and all payments must be done to ensure smooth academic tunure.</div>
                         </td>
                       </tr>
                       <tr>
                         <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                           <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:sen;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                             <tr style="border-bottom:1px solid #ecedee;text-align:left;padding:15px 0;">
                               <th style="padding: 5px 10px; color:#fff; text-align:center;">Subject</th>
                               <th style="padding: 5px 10px; color:#fff; text-align:center;">Score</th>
                               <th style="padding: 5px 10px; color:#fff; text-align:center;">Grade</th>
                             </tr>
                             <tr>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">Mathematics</td>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.mts}</td>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.mgr}</td>
                             </tr>
                             <tr>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">English</td>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.ets}</td>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.egr}</td>
                             </tr>
                             <tr>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">Science</td>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.sts}</td>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.sgr}</td>
                             </tr>
                             <tr>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.class_cateory === 'Junior High' ? 'Social Studies':'OWOP'}</td>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.class_cateory === 'Junior High' ?info.ssts:info.owts}</td>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.class_cateory === 'Junior High' ?info.ssgr:info.owgr}</td>
                             </tr>
                             <tr>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">French</td>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.fts}</td>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.fgr}</td>
                             </tr>
                             <tr>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">ICT</td>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.ictts}</td>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.ictgr}</td>
                             </tr>
                             <tr>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">Language</td>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.fnts}</td>
                               <td style="padding: 5px 10px; color:#fff; text-align:center;">${info.fngr}</td>
                             </tr>
                           </table>
                         </td>
                       </tr>
                       <tr>
                         <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                           <div style="font-family:sen;font-size:13px;line-height:20px;text-align:center;color:#ffffff;">You may continue to <a style="text-decoration: none; color:#F45E43" href="https://portal.thelogickids.com">${info.last_name.endsWith('s') ? info.last_name + "'" : info.last_name + "'s"} portal</a> for more details about this report! Regards, Adminstrator</div>
                         </td>
                       </tr>
                     </tbody>
                   </table>
                 </div>
                 <!--[if mso | IE]></td></tr></table><![endif]-->
               </td>
             </tr>
           </tbody>
         </table>
       </div>
       <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
       <div style="margin:0px auto;max-width:600px;">
         <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
           <tbody>
             <tr>
               <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                 <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                 <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                   <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color:teal;vertical-align:top;" width="100%">
                     <tbody>
                       <tr>
                         <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                           <div style="font-family:sen;font-size:13px;line-height:1;text-align:center;color:#000000;">Powered by <a style="color:orange;
           text-decoration: none; text-align:center" href="https://thelogickids.com">&copy; Logic Kids Ghana</a></div>
                         </td>
                       </tr>
                     </tbody>
                   </table>
                 </div>
                 <!--[if mso | IE]></td></tr></table><![endif]-->
               </td>
             </tr>
           </tbody>
         </table>
       </div>
       <!--[if mso | IE]></td></tr></table><![endif]-->
     </div>
   </body>
   
   </html>
    `
   }