import { _courses } from "../assets/data/courses";
import _ from 'lodash'
import Chart from 'react-apexcharts'

export const CourseGraph = ({ _history, settings }) => {

  const passMark = settings.lowerPrimaryPassMark
  const  options= {
    chart: {
      type: "line",
      height: 100,
      zoom: {
        enabled: true,
      },
    },
    stroke: {
      curve: "smooth",
      lineCap: "square",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    title: {
      text: settings.academicYear + " Academic Year",
      align: "left",
      style: {
        fontSize: "18px",
        fontFamily: "Sen",
      },
      offsetY: 0,
      offsetX: 5,
    },
    grid: {
      show: true,
      borderColor: "#90A4AE",
      padding: {
        bottom: 0,
      },
    },
    labels: ["Term 1", "Term 2", "Term 3"],
    xaxis: {
      tooltip: {
        enabled: true,
        style: {
          fontFamily: "Sen",
          
        },
      },
    },
    legend: {
      style: {
        fontFamily: "Sen",
       
      },
      position: "top",
      offsetY: 0,
      showForZeroSeries:false
    },
  }

  const getValue = (course, term) => {
    switch (course) {
      case _courses[0].value:
        return _.filter(_history, (cr) => {
          return cr.mts >= passMark && cr.term === term;
        }).length;

      case _courses[2].value:
        return _.filter(_history, (cr) => {
          return cr.sts >= passMark && cr.term === term;
        }).length;

      case _courses[1].value:
        return _.filter(_history, (cr) => {
          return cr.ets >= passMark && cr.term === term;
        }).length;

      case _courses[3].value:
        return _.filter(_history, (cr) => {
          return cr.ssts >= passMark && cr.term === term;
        }).length;

      case _courses[8].value:
        return _.filter(_history, (cr) => {
          return cr.owts >= passMark && cr.term === term;
        }).length;

      case _courses[10].value:
        return _.filter(_history, (cr) => {
          return cr.fts >= passMark && cr.term === term;
        }).length;

      case _courses[7].value:
        return _.filter(_history, (cr) => {
          return cr.hts >= passMark && cr.term === term;
        }).length;

      case _courses[4].value:
        return _.filter(_history, (cr) => {
          return cr.ictts >= passMark && cr.term === term;
        }).length;

      case _courses[9].value:
        return _.filter(_history, (cr) => {
          return cr.fnts >= passMark && cr.term === term;
        }).length;

      case _courses[5].value:
        return _.filter(_history, (cr) => {
          return cr.rmts >= passMark && cr.term === term;
        }).length;

      case _courses[6].value:
        return _.filter(_history, (cr) => {
          return cr.bdtts >= passMark && cr.term === term;
        }).length;

      case _courses[12]?.value:
        return _.filter(_history, (cr) => {
          return cr.ctts >= passMark && cr.term === term;
        }).length;

      case _courses[13]?.value: //p and h
        return _.filter(_history, (cr) => {
          return cr.phts >= passMark && cr.term === term;
        }).length;

      case _courses[11]?.value: //creative arts
        return _.filter(_history, (cr) => {
          return cr.crats >= passMark && cr.term === term;
        }).length;

      default:
        return 0;
    }
  };

  const getCoursePrimaryDetails = () => {
    const mF = getValue(_courses[0].value, "First Term");
    const mS = getValue(_courses[0].value, "Second Term");
    const mT = getValue(_courses[0].value, "Third Term");

    const sF = getValue(_courses[2].value, "First Term");
    const sS = getValue(_courses[2].value, "Second Term");
    const sT = getValue(_courses[2].value, "Third Term");

    const rmF = getValue(_courses[5].value, "First Term");
    const rmS = getValue(_courses[5].value, "Second Term");
    const rmT = getValue(_courses[5].value, "Third Term");

    const owF = getValue(_courses[8].value, "First Term");
    const owS = getValue(_courses[8].value, "Second Term");
    const owT = getValue(_courses[8].value, "Third Term");

    const eF = getValue(_courses[1].value, "First Term");
    const eS = getValue(_courses[1].value, "Second Term");
    const eT = getValue(_courses[1].value, "Third Term");

    const hF = getValue(_courses[7].value, "First Term");
    const hS = getValue(_courses[7].value, "Second Term");
    const hT = getValue(_courses[7].value, "Third Term");

    const fF = getValue(_courses[10].value, "First Term");
    const fS = getValue(_courses[10].value, "Second Term");
    const fT = getValue(_courses[10].value, "Third Term");

    const cF = getValue(_courses[4].value, "First Term");
    const cS = getValue(_courses[4].value, "Second Term");
    const cT = getValue(_courses[4].value, "Third Term");

    const fnF = getValue(_courses[9].value, "First Term");
    const fnS = getValue(_courses[9].value, "Second Term");
    const fnT = getValue(_courses[9].value, "Third Term");

    const crF = getValue(_courses[11], "First Term");
    const crS = getValue(_courses[11], "Second Term");
    const crT = getValue(_courses[11], "Third Term");

    return [
      {
        name: _courses[0]?.text,
        data: [mF, mS, mT],
      },
      {
        name: _courses[2]?.text,
        data: [sF, sS, sT],
      },
      {
        name: _courses[1]?.text,
        data: [eF, eS, eT],
      },
      {
        name: _courses[7]?.text,
        data: [hF, hS, hT],
      },
      {
        name: _courses[9]?.text,
        data: [fF, fS, fT],
      },
      {
        name: _courses[4]?.text,
        data: [cF, cS, cT],
      },
      {
        name: _courses[8]?.text,
        data: [owF, owS, owT],
      },
      {
        name: _courses[10]?.text,
        data: [fnF, fnS, fnT],
      },

      {
        name: _courses[11]?.text,
        data: [crF, crS, crT],
      },
      {
        name: _courses[5]?.text,
        data: [rmF, rmS, rmT],
      },
    ];
  };

  return (
    <Chart type="area" width={"100%"} series={getCoursePrimaryDetails()} options={options} />
  );
};
