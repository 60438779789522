import { StudentTile } from "../components/tiles";
import { useState } from "react";
import _ from "lodash";
import classPhoto from "../assets/images/class-photo.jpg";
import { classes } from "../assets/data/classes";
import axios from "axios";
import { proxy, xmail } from "../endpoints";
import { Feedback } from "../components/modals/feedback";
import { dbName } from "../assets/data/states";

export default function Students({ students, profile }) {
  const [student, setStudent] = useState(null);
  const [selected, setSelected] = useState([]);

  const [response, setResponse] = useState({
    message: "",
    type: "error",
    open: false,
  });
  const [newClass, setNewClass] = useState({
    current_class: "",
    division: "",
    class_category: "",
  });

  const [selectType, setSelectType] = useState("Deselect");

  const selectStudent = (id) => {
    const std = students.find((std) => std.id === id);
    setStudent(std);
    if (selected.includes(id)) {
      const removed = _.filter(selected, (st) => st !== id);
      setSelected(removed);
    } else {
      setSelected([...selected, id]);
    }
  };

  const selectAll = (type) => {
    setSelectType(type);
    let temp = [];
    if (type === "Select") {
      // select all
      for (var std of students) {
        temp.push(std.id);
      }
      setSelected(_.uniq(temp));
    } else {
      // Deselect
      setSelected([]);
    }
  };
  const genderStats = () => {
    const boys = _.filter(students, (std) => std.gender === "Male").length;
    const girls = _.filter(students, (std) => std.gender === "Female").length;

    return [boys, girls];
  };

  const isSelected = (student_id) => {
    return selected.includes(student_id);
  };

  const triggerAction = async (type) => {
    const data = type === "Deactivate" ? { is_active: false } : { ...newClass };
    
    try {
      for (var id of selected) {
        await axios.patch(`${proxy}/student/${id}`, data, {
          headers: {
            dbName,
            xmail
          },
        });
      
      }
      setResponse({
        open: true,
        type: "success",
        message: "Operation Completed!",
      });
    } catch (err) {
      setResponse({
        open: true,
        type: "error",
        message: err.message,
      });
    } 
  };

  return (
    <div className="flex-1 flex p-3 max-h-[95vh] overflow-hidden">
      <div className="w-full md:w-4/5 p-2 overflow-y-scroll">
        <div>
          <button
            onClick={() => setStudent(null)}
            className="bg-red-500 text-xs text-white p-2 rounded-sm shadow-md ml-2 "
          >
            View Class Profile
          </button>
          <button
            onClick={() =>
              selectAll(selectType === "Select" ? "Deselect" : "Select")
            }
            className="bg-green-500 text-xs text-white p-2 rounded-sm shadow-md ml-2"
          >
            {selectType === "Deselect" ? "Select All" : "Uncheck All"}
          </button>
        </div>

        <div className=" flex-wrap flex p-2 gap-2 w-full">
          {students?.map((std, key) => (
            <StudentTile
              key={key}
              student={std}
              select={selectStudent}
              selected={isSelected(std.id)}
            />
          ))}
        </div>
      </div>
      {student === null ? (
        <div className="hidden md:flex flex-col flex-1 bg-white">
          <img
            alt="Class "
            src={classPhoto}
            className="w-full h-1/3 object-cover"
          />
          <div className="flex justify-around w-full p-2 bg-gray-50">
            <div>
              <p>{students.length}</p>
              <h3 className="text-sm">Students</h3>
            </div>
            <div className="px-5 border-x border-x-gray-300">
              <p>{genderStats()[0]}</p>
              <h3 className="text-sm">Boys</h3>
            </div>
            <div>
              <p>{genderStats()[1]}</p>
              <h3 className="text-sm text-gray-500">Girls</h3>
            </div>
          </div>
          <div className="flex items-center space-x-2 p-3">
            <img
              src={profile.photo}
              alt="Class Teacher"
              className="w-10 h-10 bg-black object-cover"
            />
            <div>
              <p>{profile?.last_name + " " + profile?.first_name}</p>
              <p className="text-xs">{profile?.staff_id}</p>
            </div>
          </div>

          <div className="flex flex-col space-y-2 p-3">
            <div className="flex space-x-2">
              <button
                disabled={
                  selected.length === 0 ||
                  newClass.category === "" ||
                  newClass.current_class === "" ||
                  newClass.division === ""
                }      
                onClick={() => triggerAction("Change")}
                className="w-1/2 bg-yellow-600 text-sm p-1"
              >
                Change Class
              </button>
              <button
                disabled
                onClick={() => triggerAction("Deactivate")}
                className="w-1/2 bg-red-600 text-sm text-white p-1"
              >
                Deactivate
              </button>
            </div>

            {selected.length > 0 && (
              <div className="flex justify-between">
                <select
                  onChange={(e) =>
                    setNewClass({
                      ...newClass,
                      current_class: e.target.value.info,
                      category: e.target.value.category,
                    })
                  }
                  className="w-2/3"
                >
                  <option value="">New Class</option>
                  {classes.map((info, idx) => (
                    <option key={idx} value={info}>
                      {info.text}
                    </option>
                  ))}
                </select>
                <div className="flex space-x-2">
                  <div className="flex items-center space-x-1">
                    <label htmlFor="divisionA" className="text-sm">
                      A
                    </label>
                    <input
                      onChange={(e) =>
                        setNewClass({ ...newClass, division: e.target.value })
                      }
                      type="radio"
                      name="division"
                      id="divisionA"
                      value={"A"}
                    />
                  </div>
                  <div className="flex items-center space-x-1">
                    <label htmlFor="divisionB" className="text-sm">
                      B
                    </label>
                    <input
                      onChange={(e) =>
                        setNewClass({ ...newClass, division: e.target.value })
                      }
                      type="radio"
                      name="division"
                      id="divisionB"
                      value={"B"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="hidden md:flex flex-1 ">
          <div className="w-full bg-white h-full">
            <img
              alt="Student"
              src={student.photo}
              className="w-full object-contain"
            />
            <div className="flex items-center flex-col p-2 overflow-y-scroll">
              <div className="pb-3 text-center">
                <h3 className="text-lg font-bold">
                  {student.last_name + " " + student.other_names}
                </h3>
                <p className="text-xs">{student.date_of_birth?.slice(0, 10)}</p>
              </div>

              <table className="w-full table-fixed">
                <tbody>
                  <tr className="text-xs border-b border-b-gray-300">
                    <td className="p-2">Student ID</td>
                    <td className="p-2">{student.student_id}</td>
                  </tr>
                  <tr className="text-xs border-b border-b-gray-300">
                    <td className="p-2">Nationality</td>
                    <td className="p-2">{student.nationality}</td>
                  </tr>
                  <tr className="text-xs border-b border-b-gray-300">
                    <td className="p-2">Gender</td>
                    <td className="p-2">{student.gender}</td>
                  </tr>
                  <tr className="text-xs border-b border-b-gray-300">
                    <td className="p-2">Home Town</td>
                    <td className="p-2">{student.home_town}</td>
                  </tr>
                  <tr className="text-xs border-b border-b-gray-300">
                    <td className="p-2">Contact 1</td>
                    <td className="p-2">
                      <a href={`tel:${student.contact_1}`}>
                        {student.contact_1}
                      </a>
                    </td>
                  </tr>
                  <tr className="text-xs border-b border-b-gray-300">
                    <td className="p-2">Contact 2</td>
                    <td className="p-2">
                      <a href={`tel:${student.contact_2}`}>
                        {student.contact_2}
                      </a>
                    </td>
                  </tr>
                  <tr className="text-xs border-b border-b-gray-300">
                    <td className="p-2">Date Joined</td>
                    <td className="p-2">
                      {student.date_registered?.slice(0, 10)}
                    </td>
                  </tr>
                  <tr className="text-xs border-b border-b-gray-300">
                    <td className="p-2">Is Active</td>
                    <td className="p-2">
                      {student.is_active ? (
                        <span className="text-green-500">Yes</span>
                      ) : (
                        <span className="text-red-700">No</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <div className="flex bg-white fixed w-full z-50 bottom-0 left-0 flex-col md:hidden">
        <div className="flex justify-around w-full p-2 bg-gray-50">
          <div>
            <p>{students.length}</p>
            <h3 className="text-sm">Students</h3>
          </div>
          <div className="px-5 border-x border-x-gray-300">
            <p>{genderStats()[0]}</p>
            <h3 className="text-sm">Boys</h3>
          </div>
          <div>
            <p>{genderStats()[1]}</p>
            <h3 className="text-sm text-gray-500">Girls</h3>
          </div>
        </div>
        <div className="flex items-center space-x-2 p-3">
          <img
            src={profile.photo}
            alt="Class Teacher"
            className="w-10 h-10 bg-black object-cover"
          />
          <div>
            <p>{profile?.last_name + " " + profile?.first_name}</p>
            <p className="text-xs">{profile?.staff_id}</p>
          </div>
        </div>

        <div className="flex flex-col space-y-2 p-3">
          <div className="flex space-x-2">
            <button
              disabled={
                selected.length === 0 ||
                newClass.category === "" ||
                newClass.current_class === "" ||
                newClass.division === ""
              }
              
              onClick={() => triggerAction("Change")}
              className="w-1/2 bg-yellow-600 text-sm p-1"
            >
              Change Class
            </button>
            <button
              // disabled={selected.length < 1} 
              disabled
              onClick={() => triggerAction("Deactivate")}
              className="w-1/2 bg-red-600 text-sm text-white p-1"
            >
              Deactivate
            </button>
          </div>

          {selected.length > 0 && (
            <div className="flex justify-between">
              <select
                onChange={(e) =>
                  setNewClass({
                    ...newClass,
                    current_class: e.target.value.info,
                    category: e.target.value.category,
                  })
                }
                className="w-2/3"
              >
                <option value="">New Class</option>
                {classes.map((info, idx) => (
                  <option key={idx} value={info}>
                    {info.text}
                  </option>
                ))}
              </select>
              <div className="flex space-x-2">
                <div className="flex items-center space-x-1">
                  <label htmlFor="divisionA" className="text-sm">
                    A
                  </label>
                  <input
                    onChange={(e) =>
                      setNewClass({ ...newClass, division: e.target.value })
                    }
                    type="radio"
                    name="division"
                    id="divisionA"
                    value={"A"}
                  />
                </div>
                <div className="flex items-center space-x-1">
                  <label htmlFor="divisionB" className="text-sm">
                    B
                  </label>
                  <input
                    onChange={(e) =>
                      setNewClass({ ...newClass, division: e.target.value })
                    }
                    type="radio"
                    name="division"
                    id="divisionB"
                    value={"B"}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {response.open && (
        <Feedback {...response} setOpen={() => setResponse({ open: false })} />
      )}
    </div>
  );
}
