import axios from "axios";
import { useState } from "react";
import { proxy, updateData, uploadData } from "../../endpoints";
import { table_courses } from "../../assets/data/courses";
import _ from "lodash";
import { Feedback } from "./feedback";
import { dbName } from "../../assets/data/states";
import { Spin } from "../loader";

export default function TableModal({ day, profile, settings }) {
  const [subject_1, setSubject1] = useState("");
  const [loading, setLoading] = useState(false);
  const [subject_2, setSubject2] = useState("");
  const [subject_3, setSubject3] = useState("");
  const [subject_4, setSubject4] = useState("");
  const [subject_5, setSubject5] = useState("");
  const [subject_6, setSubject6] = useState("");
  const [response, setResponse] = useState({
    open: false,
    message: "",
    type: "",
  });

  const updateTable = async (e) => {
    e.preventDefault();
    setLoading(true);
    const timers = [
      {
        day,
        lesson_1: {
          subject: subject_1,
          start: 8,
        },
        lesson_2: {
          subject: subject_2,
          start: 9,
        },
        lesson_3: {
          subject: subject_3,
          start: 10,
        },
        lesson_4: {
          subject: subject_4,
          start: 11,
        },
        lesson_5: {
          subject: subject_5,
          start: 1,
        },
        lesson_6: {
          subject: subject_6,
          start: 2,
        },
      },
    ];
    // console.log(timers);

    const details = {
      class: profile.class_assigned,
      division: profile.division,
      term: settings.currentTerm,
      academic_year: settings.academicYear,
      date_set: new Date().toISOString(),
      timers,
    };

    // check if data already exist in DB
    const { data } = await axios.get(
      `${proxy}/table/get-class/${profile.class_assigned}/${profile.division}?term=${settings.currentTerm}`,
      {
        headers: { dbName },
      }
    );

    if (data?.id) {
      // update document
      // check the timer details and replace that.
      let newTimers = data?.timers || [];
      const isToday = _.find(data.timers, (tm) => tm.day === day);

      if (isToday === undefined) {
        newTimers.push(timers[0]);
      } else {
        newTimers[newTimers.indexOf(isToday)] = timers[0];
      }

      updateData({
        path: "/table/update",
        id: `${data.id}?type=table`,
        data: { ...details, timers: newTimers },
      });
      setLoading(false);
    } else {
      // new document to be uploaded
      uploadData({
        path: "/table/add",
        data: { ...details, id: new Date().getTime().toString() },
      })
        .then(() => {
          setResponse({
            open: true,
            type: "success",
            message: "Table Successfully Updated",
          });
          setLoading(false);
        })
        .catch((err) => {
          const { message } = err.reponse.data;
          setResponse({
            open: true,
            type: "error",
            message: message,
          });
          setLoading(false);
        });
    }
  };

  const filterSubject = (list) => {
    return _.filter(table_courses, (crs) => !list.includes(crs.value));
  };

  return (
    <div className="p-2 bg-white mt-2 flex flex-col space-y-3">
      <div className="-space-y-1">
        <h3 className="text-lg">{profile.class_assigned}</h3>
        <p className="text-sm text-gray-600">Modify Timetable - {day}</p>
      </div>
      <form
        onSubmit={updateTable}
        className="flex flex-col w-full  space-y-3 gap-3 p-3 "
      >
        <div className="flex flex-col md:flex-row mt-2 gap-2 ">
          <div className="w-full md:w-60">
            <label htmlFor="subject_1" className="text-sm">
              Lesson 1
            </label>
            <select
              value={subject_1}
              onChange={(e) => setSubject1(e.target.value)}
              required
              className="p-2 mt-1"
              id="subject_1"
            >
              <option value="">Select Subject</option>
              {filterSubject([
                subject_6,
                subject_5,
                subject_4,
                subject_3,
                subject_2,
              ]).map((course) => (
                <option value={course.value}>{course.text}</option>
              ))}
            </select>
          </div>
          <div className="w-full md:w-60">
            <label htmlFor="subject_2" className="text-sm">
              Lesson 2
            </label>
            <select
              onChange={(e) => setSubject2(e.target.value)}
              value={subject_2}
              required
              className="p-2 mt-1"
              id="subject_2"
            >
              <option value="">Select Subject</option>
              {filterSubject([
                subject_6,
                subject_5,
                subject_4,
                subject_3,
                subject_1,
              ]).map((course) => (
                <option value={course.value}>{course.text}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-2">
          <div className="w-full md:w-60">
            <label htmlFor="subject_3" className="text-sm">
              Lesson 3
            </label>
            <select
              onChange={(e) => setSubject3(e.target.value)}
              value={subject_3}
              required
              id="subject_3"
              className="p-2 mt-1"
            >
              <option value="">Select Subject</option>
              {filterSubject([
                subject_6,
                subject_5,
                subject_4,
                subject_1,
                subject_2,
              ]).map((course) => (
                <option value={course.value}>{course.text}</option>
              ))}
            </select>
          </div>
          <div className="w-full md:w-60">
            <label htmlFor="subject_4" className="text-sm">
              Lesson 4
            </label>
            <select
              onChange={(e) => setSubject4(e.target.value)}
              value={subject_4}
              required
              className="p-2 mt-1"
              id="subject_4"
            >
              <option value="">Select Subject</option>
              {filterSubject([
                subject_6,
                subject_5,
                subject_1,
                subject_3,
                subject_2,
              ]).map((course) => (
                <option value={course.value}>{course.text}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-2">
          <div className="w-full md:w-60">
            <label htmlFor="subject_5" className="text-sm">
              Lesson 5
            </label>
            <select
              onChange={(e) => setSubject5(e.target.value)}
              value={subject_5}
              required
              id="subject_5"
              className="p-2 mt-1"
            >
              <option value="">Select Subject</option>
              {filterSubject([
                subject_6,
                subject_1,
                subject_4,
                subject_3,
                subject_2,
              ]).map((course) => (
                <option value={course.value}>{course.text}</option>
              ))}
            </select>
          </div>
          <div className="w-full md:w-60">
            <label htmlFor="subject_6" className="text-sm">
              Lesson 6
            </label>
            <select
              onChange={(e) => setSubject6(e.target.value)}
              value={subject_6}
              required
              id="subject_6"
              className="p-2 mt-1"
            >
              <option value="">Select Subject</option>
              {filterSubject([
                subject_1,
                subject_5,
                subject_4,
                subject_3,
                subject_2,
              ]).map((course) => (
                <option value={course.value}>{course.text}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="" className="text-sm">
            {" "}
          </label>

          <button
            className=" whitespace-nowrap bg-green-500 text-sm px-3 py-1 text-white shadow-md rounded-sm"
            title="Update"
          >
            {loading ? <Spin /> : "Set Table"}
          </button>
        </div>
      </form>
      {response.open && (
        <Feedback
          {...response}
          setOpen={() => setResponse({ open: false })}
        />
      )}
    </div>
  );
}
