import { useEffect, useState } from "react";
import logo from "../assets/images/_logo.png";
import { TiUserOutline, TiLockClosedOutline } from "react-icons/ti";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { proxy } from "../endpoints";
import axios from "axios";
import { signIn } from "../endpoints/firebase/auth";
import { Feedback } from "../components/modals/feedback";
import { Link, useNavigate } from "react-router-dom";
import { Spin } from "../components/loader";
import { decryptData, encryptData, formatMail } from "../functions/crypt";
import { getDocuments } from "../endpoints/fb";
import _ from "lodash";
import { dbName } from "../assets/data/states";

export default function Login() {
  const [staffID, setStaffID] = useState("");
  const [schoolID, setSchoolID] = useState(
    decryptData(localStorage.getItem("schoolID")) || ""
  );
  const [sch, setSch] = useState(null);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    message: "",
    type: "error",
    open: false,
  });

  const [schx, setSchx] = useState([]);
  const [adminx, setAdminx] = useState([]);

  useEffect(() => {
    getDocuments({
      path: "schoolx",
      getData: setSchx,
    });

    getDocuments({
      path: "adminx",
      getData: setAdminx,
    });

  }, []);

  const navigate = useNavigate();

  const loginToApp = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // check if it is a regular staff ID GHS-ST-0001
      if (staffID.length === 11 && !staffID.includes("@")) {
        const { status, data } = await axios.get(`${proxy}/staff/${staffID}`, {
          headers: { dbName, xmail: "pilot@logickids.com" },
        });

        // Later check if account has been created.
        if (
          status === 200 &&
          data.is_active === true &&
          data.employee_category === "Teacher"
        ) {
          const mail = formatMail(data.staff_id);
          signIn(mail, password)
            .then(() => {
              // auth successful
              localStorage.setItem("xmail", encryptData(mail));

              localStorage.setItem("STAFFLOGGED", true);
              setLoading(false);
              localStorage.setItem("staffID", staffID);
              navigate("/", { replace: true });
            })
            .catch((err) => {
              console.log(err);
              setResponse({
                open: true,
                message: err.message,
                type: "error",
              });
              setLoading(false);
            });
        } else {
          setResponse({
            open: true,
            message: "Sorry, this ID not recognized.",
            type: "error",
          });
          setLoading(false);
        }
      } else {
        // for administrative logins.
        let roleUser = adminx.find(el => el.email === staffID && ["Administrator", "Head Teacher", "Level Coordinator"].includes(el.role))

        if (roleUser) {
          signIn(staffID, password)
            .then(() => {
              // successful login
              // Get time and pass this as one object... wtf is this?
              localStorage.setItem("STAFFLOGGED", true);
              console.log(roleUser)
              setLoading(false);
              localStorage.setItem("staffID", staffID);
              localStorage.setItem("role", roleUser.role)
              if (roleUser?.role === "Level Coordinator") {
                localStorage.setItem("levelAssigned", roleUser.levelAssigned)
              }
              localStorage.setItem("isAdmin", true);
              localStorage.setItem("xmail", encryptData(staffID))
              navigate("/", { replace: true });
            })
            .catch((err) => {
              setResponse({
                type: "error",
                message: err.message,
                open: true,
              });
            });
        } else {
          setResponse({
            open: true,
            message: "Sorry, this ID not recognized.",
            type: "error",
          });
          setLoading(false);
        }

      }

    } catch (err) {
      const { message } = err.response?.data
      setResponse({
        open: true,
        message: message || err.message,
        type: "error",
      });

      setLoading(false);
    }

    // if staffID exist, check password and log in.

    // else alert, incorrect password.
  };

  const verifySchool = (e) => {
    e.preventDefault();

    const sch = _.find(
      schx,
      (sh) => sh.schoolID === schoolID && sh.isActive === true
    );
    if (sch === undefined) {
      setResponse({
        open: true,
        message: "Sorry, School ID not recognized.",
        type: "error",
      });

      setSch(null);
      return;
    } else {
      localStorage.setItem("DBN", encryptData(sch.dbName));
      localStorage.setItem("schoolID", sch.schoolID);
      localStorage.setItem("sID", encryptData(sch.senderID));
      setSch(sch);
    }
  };

  return (
    <div className="bg-welcome bg-cover bg-no-repeat flex-1 flex">
      {/*  Check School ID */}
      <div className="flex flex-col bg-gray-50/3 backdrop-blur-md items-center justify-center md:w-1/2 h-screen flex-1">
        {sch !== null ? (
          <form onSubmit={loginToApp} className="flex gap-2 w-3/4  md:w-1/3 flex-col items-center">
            <img src={sch.logo} alt="School Logo" className="w-[120px]" />
            <div className="my-3 text-center text-white">
              <h2 className="text-lg">Welcome, Staff</h2>
              <p className="text-xs">Please, fill credentials to continue!</p>
            </div>
            <div className="flex bg-white w-full p-2 items-center">
              <TiUserOutline className="text-2xl text-gray-400" />
              <input
                value={staffID}
                onChange={(e) => setStaffID(e.target.value)}
                className=" p-1 bg-white  border-none text-sm"
                type="text"
                placeholder="Staff ID"
              />
            </div>

            <div className="flex bg-white p-2 w-full items-center">
              <TiLockClosedOutline className="text-2xl text-gray-400" />
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className=" p-1 bg-white border-none text-sm"
                type="password"
                placeholder="Password"
              />
            </div>
            <p className="text-xs py-4">
              <Link className="text-blue-50" to="/start/register">
                Register
              </Link>{" "}
              |{" "}
              <Link className="text-red-50" to="/start/reset">
                Reset Password
              </Link>
            </p>

            <button
              type="sumbit"
              disabled={!staffID || !password}
              className="bg-orange-500 px-10 rounded-sm py-2 text-black flex items-center justify-center"
            >
              {loading ? <Spin /> : "Continue"}
            </button>

            <p className="text-xs text-white mt-20">
              &copy; {new Date().getFullYear()}{" "}
              <a href="https://labs.logickidsgh.com/" className="text-blue-50">
                Powered by Logicians Ghana
              </a>
            </p>
          </form>
        ) : (
          <form onSubmit={verifySchool} className="flex flex-col items-center">
            <img
              src={logo}
              alt="Schoolx"
              className="w-20 h-20 object-contain"
            />
            <p className="text-2xl py-4 text-white font-bold">Logic Schoolx Portal </p>
            <div className="flex p-2">
              <input
                onChange={(e) => setSchoolID(e.target.value)}
                type="text"
                required
                value={schoolID}
                placeholder="Enter School ID"
                className="border p-2"
              />
              <button type="submit" className="bg-orange-500 px-3">
                <BsArrowRightCircleFill className="text-white text-lg" />
              </button>
            </div>
          </form>
        )}
      </div>
      {response.open && (
        <Feedback
          type={response.type}
          message={response.message}
          setOpen={() => setResponse({ ...response, open: false })}
        />
      )}
    </div>
  );
}
