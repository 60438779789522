import { useEffect, useState } from "react";
import logo from "../assets/images/_logo.png";
import { TiUserOutline, TiLockClosedOutline } from "react-icons/ti";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { proxy, uploadData } from "../endpoints";
import axios from "axios";
import { Feedback } from "../components/modals/feedback";
import { Link, useNavigate } from "react-router-dom";
import { Spin } from "../components/loader";
import { encryptData, formatMail } from "../functions/crypt";
import { getDocuments } from "../endpoints/fb";
import _ from "lodash";
import { dbName } from "../assets/data/states";

export default function Register() {
  const [staffID, setStaffID] = useState("");
  const [schoolID, setSchoolID] = useState(
    localStorage.getItem("schoolID") || ""
  );
  const [sch, setSch] = useState(null);
  const [password, setPassword] = useState({ main: "", confirm: "" });
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    message: "",
    type: "error",
    open: false,
  });

  const [schx, setSchx] = useState([]);

  useEffect(() => {
    getDocuments({
      path: "schoolx",
      getData: setSchx,
    });
  }, []);

  const navigate = useNavigate();

  const registerUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    // find user first.
    try {
      const { status, data } = await axios.get(`${proxy}/staff/${staffID}`, {
        headers: { dbName, xmail: "pilot@logickids.com" }, // the is a temporary test case
      });

      if (password.main !== password.confirm) {
        setResponse({
          message: "Sorry, passwords do not match!",
          type: "error",
          open: true,
        });
        setLoading(false);
        return;
      }

      if (
        status === 200 &&
        data.is_active === true &&
        data.employee_category === "Teacher"
      ) {
        const mail = formatMail(data.staff_id);
        const details = {
          email: mail,
          phone: "+233" + data.phone,
          uid: data.phone,
          password: password.main,
        };

        // this email is just for testing purposes
        localStorage.setItem('xmail', encryptData("pilot@logickids.com"));

        try {
          // check if user exists before creating a new one.
          const { data, status } = await uploadData({
            path: "/user/create-account",
            data: details,
          });
          
          if (status === 200 || status === 201) {
            setLoading(false);
            setResponse({
              open: true,
              message: "Account has been successfully created",
              type: "success",
            });
            setTimeout(() => navigate("/start"), 2000);
            return;
          } else {
            setLoading(false);

            setResponse({
              open: true,
              message: data?.message,
              type: "error",
            });
          }
        } catch (err) {
      
          setResponse({
            message: err.response.data||err.message,
            type: "error",
            open: true,
          });
          setLoading(false);
        }
      } else {
        setLoading(false);
        setResponse({
          message: "Sorry, can register with this ID",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setLoading(false);
      // Last thing to be done.
      const { message } = err.response.data;
      console.log(err);
      setResponse({
        message,
        type: "error",
        open: true,
      });
    }
  };

  const verifySchool = (e) => {
    e.preventDefault();

    const sch = _.find(
      schx,
      (sh) => sh.schoolID === schoolID && sh.isActive === true
    );
    if (sch === undefined) {
      setResponse({
        open: true,
        message: "Sorry, School ID not recognized.",
        type: "error",
      });

      setSch(null);
      return;
    } else {
      localStorage.setItem("DBN", encryptData(sch.dbName));
      localStorage.setItem("sID", encryptData(sch.senderID));
      setSch(sch);
    }
  };

  return (
    <div className="bg-welcome bg-cover bg-no-repeat flex-1 flex">
      <div className="md:w-1/2 bg-gray-50/3 backdrop-blur-md  h-screen flex-1 flex flex-col items-center justify-center">
        {/*  Check School ID */}
        {sch !== null ? (
          <form onSubmit={registerUser} className="flex  gap-2 w-3/4 md:w-1/3  flex-col items-center">
            <img src={sch.logo} alt="School Logo" className="w-[120px]" />
            <div className="my-3 text-white text-center">
              <h2 className="text-lg">Welcome</h2>
              <p className="text-xs">Register to Continue</p>
            </div>
            <div className="flex bg-white w-full  p-2 items-center">
              <TiUserOutline className="text-2xl text-gray-400" />
              <input
                value={staffID}
                onChange={(e) => setStaffID(e.target.value)}
                className=" p-1 bg-gray-50 border-none text-sm"
                type="text"
                placeholder="Staff ID"
              />
            </div>

            <div className="flex bg-white w-full  p-2 items-center">
              <TiLockClosedOutline className="text-2xl text-gray-400" />
              <input
                value={password.main}
                onChange={(e) =>
                  setPassword({ ...password, main: e.target.value })
                }
                className=" p-1 bg-gray-50 border-none text-sm"
                type="password"
                placeholder="Password"
              />
            </div>
            <div className="flex bg-white w-full  p-2 items-center">
              <TiLockClosedOutline className="text-2xl text-gray-400" />
              <input
                value={password.confirm}
                onChange={(e) =>
                  setPassword({ ...password, confirm: e.target.value })
                }
                className=" p-1 bg-gray-50 border-none text-sm"
                type="password"
                placeholder="Confirm Password"
              />
            </div>
            <p className="text-xs py-2 text-white">
              Already have an account?{" "}
              <Link className="text-blue-500" to="/start">
                Login
              </Link>
            </p>
            <button
              type="sumbit"
              disabled={!staffID || !password.main || !password.confirm}
              className="btn flex items-center justify-center"
            >
              {loading ? <Spin /> : "Register"}
            </button>

            <p className="text-xs mt-20">
              &copy; {new Date().getFullYear()}{" "}
              <a href="https://labs.logickidsgh.com/" className="text-blue-500">
                Powered by Logicians Ghana
              </a>
            </p>
          </form>
        ) : (
          <form onSubmit={verifySchool} className="flex flex-col items-center">
            <img
              src={logo}
              alt="Schoolx"
              className="w-20 h-20 object-contain"
            />
            <p className="text-xl py-4 font-bold text-white">Create Account</p>
            <div className="flex p-2">
              <input
                onChange={(e) => setSchoolID(e.target.value)}
                type="text"
                placeholder="Enter School ID"
                className="border p-2"
                value={schoolID}
                required
              />
              <button type="submit" className="bg-orange-500 px-3">
                <BsArrowRightCircleFill className="text-white text-lg" />
              </button>
            </div>
          </form>
        )}

        {response.open && (
          <Feedback
            type={response.type}
            message={response.message}
            setOpen={() => setResponse({ ...response, open: false })}
          />
        )}
      </div>
    </div>
  );
}
