import { NavLink, useNavigate } from "react-router-dom";
import { BsPinAngleFill, BsPower } from "react-icons/bs";
import { MdLockReset, MdOutlineAddAPhoto } from "react-icons/md";
import { HiMenuAlt3 } from "react-icons/hi";
import { HiOutlineBellSlash } from "react-icons/hi2";
import { useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";
import nowork from "../assets/images/no-work.png";
import { proxy, uploadData, updateData, xmail } from "../endpoints";
import { dbName } from "../assets/data/states";
import { getContacts } from "../functions/get-data";
import { Feedback } from "./modals/feedback";
import { Spin } from "./loader";
import { signUserOut } from "../endpoints/firebase/auth";
import { checkFile } from "../functions";
import { deleteFile, uploadFile } from "../endpoints/fb";

export const SideLink = ({ Icon, path, title, toggle, action, close }) => {
  const currentNode = window.location.pathname;

  const node =
    path === "" ? (
      <button
        title={title}
        onClick={() => action()}
        className={`flex items-center space-x-3 p-1 ${
          path === currentNode
            ? "border-r-2 text-red-500 border-red-500 rounded-md"
            : "text-gray-400"
        }`}
      >
        <Icon className="text-xl text-gray-500" />{" "}
        <p className={`${toggle ? "hidden" : "inline"} text-sm `}>{title}</p>
      </button>
    ) : (
      <NavLink
        title={title}
        onClick={close}
        className={({ isActive }) =>
          `flex flex-row items-center space-x-3 p-1 ${
            isActive
              ? " border-red-500 md:border-none border-r-2 text-red-500 md:border-red-500 rounded-md"
              : "text-gray-400"
          }`
        }
        to={path}
      >
        <Icon
          className={`text-xl ${
            path === currentNode ? "text-red-500" : "text-gray-500"
          }`}
        />{" "}
        <p className={`text-xs md:text-sm truncate`}>{title}</p>
      </NavLink>
    );

  return node;
};

export const StaffProfile = ({ profile, setToggle, toggle }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({
    open: false,
    type: "",
    message: "",
  });
  const navigate = useNavigate();

  const logout = () => {
    signUserOut().then(() => {
      localStorage.clear();
      navigate("/start");
    });
  };

  const uploadPhoto = (e) => {
    // find old photo and delete.
    setLoading(true)
    let oldPhoto = profile.photo;
    if (checkFile(e.target.files[0])) {
      uploadFile({
        path: `/staff/${new Date().getFullYear()}/${new Date().getTime()}`,
        file: e.target.files[0],
        getLink: (link) => {
          updateData({
            path: "/staff",
            id: profile.id,
            data: { ...profile, photo: link },
          })
            .then(() => {
              setResponse({
                open: true,
                message: "Profile successfully updated",
                type: "success",
              });

              deleteFile({url:oldPhoto});
              setLoading(false)
            })
            .catch((err) =>{
              setLoading(false)
              setResponse({
                open: true,
                message: err.message,
                type: "error",
              })
            }
              
            );
            
        },
      });
    } else {
      setResponse({
        open: true,
        message: "Sorry, Photo must be less than 2MB",
      });
    }
  };

  return (
    <div className="relative">
      <div className="flex w-full items-center gap-4">
        <img
          onClick={() => setShow(!show)}
          src={profile?.photo}
          alt={profile?.first_name}
          className="w-10 h-10 object-cover rounded-full"
        />
        <div className="hidden md:block pl-1">
          <h3 className="text-sm">
            {profile?.title + " " + profile?.last_name}
          </h3>
          <p className="text-xs">
            {profile?.class_assigned + profile?.division}
          </p>
        </div>
        <button onClick={() => setToggle(!toggle)} className="block md:hidden">
          <HiMenuAlt3 className="text-3xl text-gray-500" />
        </button>
      </div>

      {show && (
        <div className="flex gap-3 flex-col absolute w-[200px] shrink-0 top-12 right-0 bg-red-500 z-[5000]   p-2 space-y-2 rounded-b-lg">
          <button className="gap-1 items-center hidden">
            <MdLockReset className="text-white" />{" "}
            <p className="text-sm text-white">Reset Password</p>
          </button>

          <label
            htmlFor="photo"
            className="flex cursor-pointer gap-1 items-center my-2"
          >
            <MdOutlineAddAPhoto className="text-white" />{" "}
            {loading ? <Spin/> : <p className="text-sm text-white">Change Photo</p>}
          </label>

          <button onClick={logout} className="flex gap-1 items-center my-2">
            <BsPower className="text-white" />{" "}
            <p className="text-sm text-white">Log Out</p>
          </button>

          <input
            type="file"
            accept="image/*"
            name="photo"
            id="photo"
            onChange={uploadPhoto}
            className="hidden"
          />
        </div>
      )}

      {response.open && (
        <Feedback setOpen={() => setResponse({ open: false })} {...response} />
      )}
    </div>
  );
};

export const NoticeBoard = ({ messages }) => (
  <div className="min-h-56 max-h-80 z-50 overflow-y-auto p-2 fixed top-16 right-5 w-80 md:w-72 bg-white shadow-lg rounded-b-xl ">
    <h3 className="p-2 text-lg sticky -top-2 bg-white w-full">Notifications</h3>
    <div className="space-y-3 mt-5 pb-5">
      {messages.length > 0 ? (
        messages.map((idx) => (
          <div
            key={idx}
            className="flex  flex-col space-x-2 p-2 bg-gray-100 border border-gray-200"
          >
            <p className="text-xs">
              <BsPinAngleFill className="text-2xl" /> No New
            </p>
            <p className="text-right text-xs py-2">10:43:00AM - Adminstrator</p>
          </div>
        ))
      ) : (
        <div className="flex flex-col items-center justify-center">
          <HiOutlineBellSlash className="text-red-500 text-4xl" />
          <p className="text-red-300 text-sm">No New Notifications</p>
        </div>
      )}
    </div>
  </div>
);

export const TableSummary = ({ profile, term }) => {
  const [table, setTable] = useState(null);
  useEffect(() => {
    async function readData() {
      try {
        const { data } = await axios.get(
          `${proxy}/table/get-class/${profile.class_assigned}/${profile.division}?term=${term}`,
          {
            headers: {
              dbName,
              xmail: xmail
            },
          }
        );
        setTable(data || {});
      } catch (err) {
        console.log(err);
      }
    }
    readData();
  }, [profile, term]);

  const getTodaySubjects = () => {
    let today = new Date().toLocaleString("en-GB", { weekday: "long" });
    const data = _.find(table?.timers || [], (tm) => tm.day === today) || {};
    
    if (["Saturday", "Sunday"].includes(today)) {
      return (
        <div className="flex items-center justify-center flex-col">
          <img
            src={nowork}
            alt="No Work"
            className="w-20 h-20 object-contain"
          />
          <div className="text-center space-y-1">
            <p className="text-lg">{today}</p>
            <p className="text-xs">Enjoy Your Weekend</p>
          </div>
        </div>
      );
    } else {
      return (
        <table className="table table-auto w-full">
          <thead>
            <tr className="text-sm border-b border-b-gray-400">
              <td className="p-2">Subject</td>
              <td className="p-2">Time</td>
            </tr>
          </thead>
          <tbody>
            <tr className="text-xs">
              <td className="p-2 border">{data.lesson_1?.subject}</td>
              <td className="p-2 border">8:00</td>
            </tr>
            <tr className="text-xs">
              <td className="p-2 border">{data.lesson_2?.subject}</td>
              <td className="p-2 border">9:00</td>
            </tr>
            <tr className="text-xs">
              <td className="p-2 border">{data.lesson_3?.subject}</td>
              <td className="p-2 border">10:00</td>
            </tr>
            <tr className="text-xs">
              <td className="p-2 border">{data.lesson_4?.subject}</td>
              <td className="p-2 border">11:00</td>
            </tr>
            <tr className="text-xs">
              <td className="p-2 border">{data.lesson_5?.subject}</td>
              <td className="p-2 border">1:00</td>
            </tr>
            <tr className="text-xs">
              <td className="p-2 border">{data.lesson_6?.subject}</td>
              <td className="p-2 border">2:00</td>
            </tr>
          </tbody>
        </table>
      );
    }
  };
  return (
    <div className="w-full  h-screen bg-white  p-2">
      <div className="my-2">
        <h3 className="text-md">Time Table</h3>
        <p className="text-xs">Today - {new Date().toLocaleString()}</p>
      </div>

      {table === null ? (
        <div className="flex h-56 border-b-4 rounded-b-md border-red-500  items-center justify-center flex-col">
          <img
            src={nowork}
            alt="Table Not Set"
            className="w-20 h-20 object-contain"
          />
          <div className="text-center space-y-1">
            <p className="text-lg">Please set time table</p>
            <p className="text-xs">Help parents know what's next</p>
          </div>
        </div>
      ) : (
        getTodaySubjects()
      )}
    </div>
  );
};

export const Messages = ({ students }) => {
  const [messageType, setMessageType] = useState("Email");
  const [targets, setTarget] = useState([[]]);
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState({
    open: false,
    message: "",
    type: "",
  });

  const getTarget = () => {
    return getContacts(students, messageType);
  };

  const contacts = getTarget();

  const sendMessage = async (e) => {
    e.preventDefault();

    const data = {
      message,
      target: typeof targets === Array ? targets : [targets],
      sender_id: "LOGIC KIDS",
    };

    if (messageType === "SMS") {
      try {
        await uploadData({
          path: "messages/send-sms",
          data,
        });

        setMessage("");
      } catch (err) {
        setResponse({
          message: err.message,
          open: true,
          type: "error",
        });
      }
    } else if (messageType === "Email") {
      // send Email
    }
  };
  return (
    <div className="w-full h-60 bg-white overflow-y-scroll relative p-2 shadow-md rounded-sm">
      <h3 className="my-1 border-b border-b-gray-400 sticky bg-white pb-2 top-0 left-0">
        Messages
      </h3>
      <form className="space-y-1" onSubmit={sendMessage}>
        <div className="flex space-x-1">
          <select
            onChange={(e) => setMessageType(e.target.value)}
            value={messageType}
            className="p-2 w-1/3"
          >
            <option value="" className="truncate">
              Type
            </option>
            <option value="SMS" className="truncate">
              SMS
            </option>
            <option value="Email" className="truncate">
              Email
            </option>
          </select>

          <select
            onChange={(e) => setTarget(e.target.value)}
            className="p-2  w-2/3"
          >
            <option value="" className="truncate">
              Select Target
            </option>
            {contacts.map((info, idx) => (
              <option value={info.value} key={idx}>
                {info.text}
              </option>
            ))}
          </select>
        </div>

        <textarea
          value={message}
          placeholder="Enter Message Content Here"
          onChange={(e) => setMessage(e.target.value)}
          cols="30"
          rows="10"
          className="border w-full h-24 p-2"
        ></textarea>
        <button
          disabled={!message || targets.length < 1}
          type="submit"
          className="text-xs bg-green-500 p-1 text-white rounded-sm "
        >
          Send Message
        </button>
      </form>
      {response.open && (
        <Feedback
          setOpen={() => setResponse({ open: false })}
          message={response.message}
          type={response.type}
        />
      )}
    </div>
  );
};
