import { Outlet } from "react-router-dom";
import welcome from "../assets/images/welcome.png";

export default function Board() {
  return (
    <main className="flex flex-1 bg-white relative">
      <div className="hidden md:flex md:w-1/2 h-screen bg-gray-200 items-center justify-center flex-col bg-contain bg-center bg-no-repeat">
        <img src={welcome} alt="Schoolx" className="w-60 h-60 object-contain" />
        <div className="flex flex-col gap-2 text-center">
          <h1 className="text-2xl font-medium">Logic Schoolx</h1>
          <p className="text-sm">Transforming Schools, One Click at a Time</p>
        </div>
      </div>
      <Outlet />
    </main>
  );
}
