import { CheckStaff, VerifyPin, NewPassword } from "../components/reset";
import { useState } from "react";
import vid from "../assets/videos/dribbble.mp4";

export default function ResetPassword() {
  const [node, setNode] = useState(0);

  const nodes = [
    <CheckStaff nextNode={setNode} />,
    <VerifyPin nextNode={setNode} />,
    <NewPassword nextNode={setNode} />,
  ];

  return (
    <div className="flex-1 flex items-center justify-center h-screen md:mx-20">
      <div className="w-80 md:w-1/2 flex rounded-md overflow-hidden bg-white ">
        <div className="hidden md:flex w-1/2 h-full">
          <video src={vid} autoPlay loop className="object-cover h-full " />
        </div>
        <div className="flex-1">{nodes[node]}</div>
      </div>
    </div>
  );
}
