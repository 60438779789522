import { useRef, useState } from "react";
import logo from "../../assets/images/_logo.png";
import { Spin } from "../loader";
import { proxy, updateData } from "../../endpoints";
import { Feedback } from "../modals/feedback";
import axios from "axios";
import { dbName } from "../../assets/data/states";

export const VerifyPin = ({ nextNode }) => {
  const [check, setCheck] = useState(false);
  const [response, setResponse] = useState({
    message: "",
    type: "error",
    open: false,
  });

  const pin1 = useRef();
  const pin2 = useRef();
  const pin3 = useRef();
  const pin4 = useRef();

  const moveToNext = (pin, next) => {
    if (pin.current.value.length >= pin.current.maxLength) {
      next.current.focus();
    }
  };

  const startCheck = async () => {
    setCheck(true);
    const staffID = localStorage.getItem("STAFF_ID");

    try {
      const pin =
        pin1.current.value +
        pin2.current.value +
        pin3.current.value +
        pin4.current.value;

      const { data } = await axios.get(`${proxy}/reset/${staffID}`, {
        headers: {
          dbName,
        },
      });

      const { code, id } = data;
      console.log(code, id);
      // eslint-disable-next-line
      if (code == pin) {
        // set this data to expired.
        updateData({
          path: "/reset",
          id,
          data: { has_expired: true },
        }).then(() => {
          setCheck(false);
          setTimeout(() => nextNode(2), 2000);
        });
      } else {
        setResponse({
          open: true,
          type: "error",
          message: "Codes do not match",
        });
      }
    } catch (err) {
      setResponse({
        open: true,
        type: "error",
        message: err.message,
      });
      setCheck(false);
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()} className="min-h-80 w-80 p-5 flex flex-col gap-5 items-center justify-center relative">
      {/* Render only this if Verification is Send */}
      <div className="flex flex-col text-center items-center">
        <img
          src={logo}
          alt={"Logic Kids Logo"}
          className="w-20 h-20 object-contain"
        />
        <h3 className="text-xl">Enter Verification Code</h3>
        <p className="text-sm">Enter the code sent to your phone</p>
      </div>
      <div className="flex gap-5">
        <input
          type="text"
          ref={pin1}
          maxLength={1}
          onKeyUp={() => moveToNext(pin1, pin2)}
          required
          className="w-10 h-10 bg-black text-yellow-500 text-center rounded-md"
        />
        <input
          type="text"
          ref={pin2}
          maxLength={1}
          required
          onKeyUp={() => moveToNext(pin2, pin3)}
          className="w-10 h-10 bg-black text-yellow-500 text-center rounded-md"
        />
        <input
          type="text"
          ref={pin3}
          maxLength={1}
          required
          onKeyUp={() => moveToNext(pin3, pin4)}
          className="w-10 h-10 bg-black text-yellow-500 text-center rounded-md"
        />
        <input
          type="text"
          ref={pin4}
          maxLength={1}
          required
          onKeyUp={() => startCheck()}
          className="w-10 h-10 bg-black text-yellow-500 text-center rounded-md"
        />
      </div>
      <button
      disabled
        className={`${
          check ? "bg-green-500 text-white" : "bg-black text-yellow-400"
        } w-56 p-2 flex items-center justify-center`}
        onClick={() => {}}
      >
        {check ? (
          <span className="flex items-center space-x-2">
            <Spin /> <p className="text-xs">Verifying</p>
          </span>
        ) : (
          "Enter PIN"
        )}
      </button>

      {response.open && (
        <Feedback
          message={response.message}
          type={response.type}
          setOpen={() => setResponse({ open: false })}
        />
      )}
    </form>
  );
};
