import { RxUpdate, RxCheck } from "react-icons/rx";
import { FcCancel } from "react-icons/fc";

import _ from "lodash";
export const Tiles = ({ info, description, color  }) => (
  <div className={`w-56 ${color}  p-4 shadow-md rounded-md`}>
    <h3 className="text-xs text-white truncate">{description}</h3>
    <h1 className="text-md text-gray-50 md:text-lg truncate">{info}</h1>
  </div>
);

export const Tile2 = ({ info, description }) => (
  <div className="flex flex-col justify-center p-2 w-1/2 bg-white rounded-sm shadow-md">
    <p className="text-xs truncate">{description}</p>
    <p className="text-md truncate">{info}</p>
  </div>
);

export const LongTile = ({ students, color }) => (
  <div className={`w-full md:w-56 ${color}  p-4 flex justify-around shadow-md rounded-md`}>
    <div>
      <p className="text-xs text-gray-500">Students</p>
      <h3 className="text-lg">{students?.length}</h3>
    </div>
    <div>
      <p className="text-xs text-gray-500">Boys</p>
      <h3 className="text-lg">
        {_.filter(students, (std) => std.gender === "Male").length}
      </h3>
    </div>
    <div>
      <p className="text-xs text-gray-500">Girls</p>
      <h3 className="text-lg">
        {_.filter(students, (std) => std.gender === "Female").length}
      </h3>
    </div>
  </div>
);

export const StudentTile = ({ student, select, selected }) => (
  <div
    onClick={() => select(student.id)}
    className="flex relative flex-col justify-center items-center  overflow-hidden w-[48%] md:w-[200px] md:h-[250px] p-2 shadow-md bg-white rounded-md"
  >
    <input
      type="checkbox"
      checked={selected}
      className="absolute right-2 top-2 w-5 h-5 z-50"
    />
    <img
      src={student.photo}
      alt={student.last_name}
      className="w-20 h-20 md:w-28 md:h-28 object-cover rounded-full"
    />

    <div className="text-center mt-2">
      <h3 className="text-sm md:text-md truncate font-medium">
        {student.last_name}
      </h3>
      <p className="text-xs">{student.student_id}</p>
    </div>
  </div>
);

export const AttendanceTile = ({ history }) => (
  <div className="w-72 flex bg-gray-100 border mt-5 justify-around items-center  p-2 rounded-md">
    <div className="border-r p-2 border-r-gray-200">
      <h3 className="text-2xl">{history[0]}</h3>
      <p className="text-xs text-gray-500">Present</p>
    </div>
    <div className="flex space-x-10 items-center p-2">
      {/* Number of Boys */}
      <div>
        <h3 className="text-2xl">{history[1]}</h3>
        <p className="text-xs text-gray-500">Boys</p>
      </div>
      <div>
        <h3 className="text-2xl">{history[2]}</h3>
        <p className="text-xs text-gray-500">Girls</p>
      </div>
    </div>
  </div>
);

export const StudentExamTile = ({
  student,
  student_id,
  select,
  processed,
  processUpdate,
}) => (
  <div
    className={`flex items-center justify-between p-2 rounded-md bg-white w-80 md:w-72 ${
      student_id === student.student_id ? "border-y-2 border-x-0 md:border-y-0 md:border-x-2 border-green-500" : ""
    } shadow-md`}
  >
    {/* Image and Name */}
    <div
      onClick={() => (processed ? {} : select(student.student_id))}
      className={`flex ${!processed ? 'cursor-pointer' :'cursor-not-allowed' }   items-center w-[90%] space-x-1`}
    >
      <img src={student.photo} alt={student?.last_name} className="w-8 h-8 object-cover" />
      <div className="-space-y-1 w-fit overflow-hidden">
        <h3 className="md:text-md text-sm  truncate">
          {student.last_name + " " + student.other_names}
        </h3>
        <div className="flex text-xs">
          {processed === true ? (
            <span className="flex items-center">
              {" "}
              <RxCheck className="text-lg text-green-500 font-bold" /> Processed
            </span>
          ) : (
            <span className="flex items-center">
              {" "}
              <FcCancel /> Not Processed
            </span>
          )}
        </div>
      </div>
    </div>
    <button
      disabled={processed === false}
      onClick={() => processUpdate(student.student_id)}
    >
      <RxUpdate />
    </button>
  </div>
);
