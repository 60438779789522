import {auth } from './init'
import {
  setPersistence,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  updateProfile,
  browserSessionPersistence,
} from "firebase/auth";

setPersistence(auth, browserSessionPersistence)
export function signUp(email:string, password:string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  
  export async function signIn(email:string, password:string) {
   
      await signInWithEmailAndPassword(auth, email, password);
    
  }
  
  export async function updateUserProfile(data:any) {
    //eslint-disable-next-line
    await updateProfile(auth.currentUser,data)
  }
  
  export function signUserOut() {
    return signOut(auth)
  }