import _ from "lodash";
export const sortResults = (arr) => {
 const result = arr
    .sort((a, b) => {
      return a - b;
    })
    .reverse();

  return _.uniq(result)
};

export const getImage = async (url, exam) => {
  await fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      reader.onload = () => {
        exam.photoURI = reader.result;
        //   this.setState({ data_image: reader.result });
      };
      reader.onerror = (err) => {
        console.log(err);
      };
      reader.readAsDataURL(blob);
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
};


export const maxDate = () => {
    var today = new Date();
    var day = today.getDate();
    var month = today.getMonth() + 1;
    var year = today.getFullYear();
  
    if (day < 10) {
      day = "0" + day;
    }
  
    if (month < 10) {
      month = "0" + month;
    }
  
    return year + "-" + month + "-" + day;
  };