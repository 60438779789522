export const tracks = [
  {
    category: "General",
    group: ["Nursery 1", "Nursery 2"],
    fields: [
      { name: "Can throw, catch and kick ball" },
      { name: "Active and enjoys outdoor play" },
    ],
    remarks: ["Sometimes", "Never", "Always"],
  },
  {
    category: "Health",
    group: ["Nursery 1", "Nursery 2"],
    fields: [
      { name: "Wears clean clothes" },
      { name: "Attends toilet at acceptable place and times" },
      { name: "Eat tidily and independently" },
      { name: "Washes and clean hands after toilet, meal, play" },
    ],
    remarks: ["Sometimes", "Never", "Always"],
  },
  {
    category: "Emotional and Social Development",
    group: ["Nursery 1", "Nursery 2"],
    fields: [
      { name: "Remains cheerful" },
      { name: "Mix with others and show co-operation" },
      { name: "Show confidence during different situations" },
      { name: "Show aggression" },
    ],
    remarks: ["Sometimes", "Never", "Always"],
  },
  {
    category: "Cognitive and Language Development",
    group: ["Nursery 1", "Nursery 2"],
    fields: [
      { name: "Can recite rhymes" },
      { name: "Tell name, sex and common objects" },
      { name: "Solve simple puzzles (sorting and matching)" },
      { name: "Understand and use simple words" },
      { name: "Can scribble and write numeral" },
    ],
    remarks: ["Sometimes", "Never", "Always"],
  },
  {
    category: "Music, Art and Creativity",
    group: ["Nursery 1", "Nursery 2"],
    fields: [{ name: "Enjoy music and dance" }],
    remarks: ["Sometimes", "Never", "Always"],
  },
  // KG 2.
  {
    category: "Environment Studies",
    group: ["KG 1", "KG 2"],
    fields: [
      { name: "Building A Healthy Individual" },
      { name: "Physical Environment" },
      { name: "Social Environment" },
    ],
    remarks: ["Very Good", "Good", "Need Attention", "Need Special Attention"],
  },
  {
    category: "Mathematics / Numeracy",
    group: ["KG 1", "KG 2"],
    fields: [{ name: "Classification" }, { name: "Numbers and Number" }],
    remarks: ["Very Good", "Good", "Need Attention", "Need Special Attention"],
  },
  {
    category: "Languages and Literacy",
    group: ["KG 1", "KG 2"],
    fields: [
      { name: "Pre Reading Activities" },
      { name: "Oral Skills" },
      { name: "Writing" },
    ],
    remarks: ["Very Good", "Good", "Need Attention", "Need Special Attention"],
  },
  {
    category: "Physical Environment",
    group: ["KG 1", "KG 2"],
    fields: [
      { name: "Gross Motor Development" },
      { name: "Fine Motor Skills" },
      { name: "Sports and Games" },
    ],
    remarks: ["Very Good", "Good", "Need Attention", "Need Special Attention"],
  },
  {
    category: "Creative Arts",
    group: ["KG 1", "KG 2"],
    fields: [
      { name: "Visual Arts Creativity & Drawing" },
      { name: "Music, Dance & Drawing" },
    ],
    remarks: ["Very Good", "Good", "Need Attention", "Need Special Attention"],
  },
];

export const table_tracks = [
  {
    group: ["Nursery 1", "Nursery 2"],
    remarks: ["Sometimes", "Never", "Always"],
    initials:["S", "N", "A"]

  },
  {
    group: ["KG 2", "KG 1"],
    remarks: ["Very Good", "Good", "Need Attention", "Need Special Attention"],
    initials:["VG", "G", "NA", "NSA"]
  },
];
