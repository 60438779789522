import { VscEyeClosed } from "react-icons/vsc";
import { useState, useRef } from "react";
import logo from "../../assets/images/_logo.png";
import { Feedback } from "../modals/feedback";
import { updateData } from "../../endpoints";
import { formatMail } from "../../functions/crypt";

export const NewPassword = () => {
  const [password, setPassword] = useState({
    code1: "",
    code2: "",
  });
  const [phone, setPhone] = useState("")

  const [response, setResponse] = useState({
    type: "",
    open: false,
    message: "",
  });

  const pwd = useRef();
  const pwd1 = useRef();

  const showPassword = (node) => {
    if (node.current.type === "text") {
      node.current.type = "password";
    } else {
      node.current.type = "text";
    }
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    const id = localStorage.getItem("STAFF_ID");
    if (password.code1.trim() !== password.code2.trim()) {
      setResponse({
        open: true,
        type: "error",
        message: "Sorry, Passwords do no match",
      });
    } else {
      const mail = formatMail(id)
      const { status, data } = await updateData({
        path: "/user/modify",
        id: `${phone}?type=pwd`,
        data: {
          password: password.code1,
          email: mail.toLowerCase(),
          uid:phone,
        },
      });

      let isSuccess = status === 200 || status === 201;
      setResponse({
        type: isSuccess ? "success" : "error",
        open: true,
        message: data.message,
      });
    }
  };

  return (
    <>
      <form
        onSubmit={resetPassword}
        className="h-full w-80 md:w-full md:p-5 flex flex-col  justify-center"
      >
        {/* Render only this if Verification is Send */}
        <div className="flex flex-col text-center items-center">
          <img
            src={logo}
            alt={"Logic Kids Logo"}
            className="w-20 h-20 object-contain"
          />
          <h3 className="text-xl">Enter New Password</h3>
          <p className="text-sm">Reset your Password</p>
        </div>
        <div className="flex flex-col my-2">
          <label htmlFor="phone" className="text-sm">
            Phone
          </label>
          <div className="flex items-center border gap-2  p-1">
            <input
              required
              onChange={(e) =>
                setPhone(e.target.value)
              }
              value={phone}
              minLength={10}
              maxLength={10}
              placeholder="Phone Number"
              type="phone"
              className="bg-white p-2 flex-1"
            />
          </div>
        </div>
        <div className="flex flex-col my-2">
          <label htmlFor="password" className="text-sm">
            Password
          </label>
          <div className="flex items-center border gap-2  p-1">
            <input
              required
              onChange={(e) =>
                setPassword({ ...password, code1: e.target.value })
              }
              value={password.code1}
              ref={pwd}
              minLength={8}
              placeholder="New Password"
              type="password"
              className="bg-white p-2 flex-1"
            />
            <VscEyeClosed
              className="text-lg cursor-pointer"
              onClick={() => showPassword(pwd)}
            />
          </div>
        </div>
        <div className="flex flex-col mb-2">
          <label htmlFor="password" className="text-sm">
            Confirm Password
          </label>
          <div className="flex items-center border gap-2 p-1">
            <input
              required
              onChange={(e) =>
                setPassword({ ...password, code2: e.target.value })
              }
              value={password.code2}
              ref={pwd1}
              minLength={8}
              placeholder="New Password"
              type="password"
              className="bg-white p-2 flex-1"
            />
            <VscEyeClosed
              className="text-lg cursor-pointer"
              onClick={() => showPassword(pwd1)}
            />
          </div>
        </div>
        <button
          type="submit"
          className="p-2 bg-black mt-5 text-yellow-500 rounded-sm text-sm"
        >
          Set Password
        </button>
      </form>
      {response.open && (
        <Feedback setOpen={() => setResponse({ open: false })} {...response} />
      )}
    </>
  );
};
