export const classes = [
    {
        value: 'Nursery 1',
        text: 'Nursery 1',
        category: "Pre School",
        index: 0

    },
    {
        value: 'Nursery 2',
        text: 'Nursery 2',
        category: "Pre School",
        index: 1

    },
    {
        value: 'KG 1',
        text: 'KG 1',
        category: "Pre School",
        index: 2

    },
    {
        value: 'KG 2',
        text: 'KG 2',
        category: "Pre School",
        index: 3

    },
    {
        value: 'Basic 1',
        text: 'Basic 1',
        category: "Lower Primary",
        index: 4

    },
    {
        value: 'Basic 2',
        text: 'Basic 2',
        category: "Lower Primary",
        index: 5

    },
    {
        value: 'Basic 3',
        text: 'Basic 3',
        category: "Lower Primary",
        index: 6

    },
    {
        value: 'Basic 4',
        text: 'Basic 4',
        category: "Upper Primary",
        index: 7

    },

    {
        value: 'Basic 5',
        text: 'Basic 5',
        category: "Upper Primary",
        index: 8

    },

    {
        value: 'Basic 6',
        text: 'Basic 6',
        category: "Upper Primary",
        index: 9

    },
    {
        value: 'Basic 7',
        text: 'Basic 7',
        category: "Junior High",
        index: 10

    },
    {
        value: 'Basic 8',
        text: 'Basic 8',
        category: "Junior High",
        index: 11

    },
    {
        value: 'Basic 9',
        text: 'Basic 9',
        category: "Junior High",
        index: 12

    }
]