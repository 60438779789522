
import { signature } from "../assets/images/data-uri/head-sign";
import { classes } from "../assets/data/classes";

export const progressSheet = (examData, record) => {

  const getNextClass = () => {
    const currentClassIndex = classes.findIndex(c => c.value === record.current_class);
    return classes[currentClassIndex + 1].value.toUpperCase()
  }
  let body = [];
  for (var dt of examData) {
    // push the first category.
    body.push([
      {
        text: dt.category,
        colSpan:2, 
        bold: true,
        fillColor: "#f9f9f9",
        alignment: "center",
        margin: [0, 5, 0, 5],
      },
      {
        text:""
      }
    ]);
    // get sub categores of data
    for (var sub of dt.data) {
      // push the sub category and marks
      body.push([
        {
          text: sub.description,
        },
        {
          text: sub.remarks,
        },
      ]);
    }
  }

  return [
    {
      image: record.photoURI,
      fit: [120, 80],
      absolutePosition: { x: 495, y: 40 },
    },
    {
      text: record.student_id,
      fontSize: 10,
      absolutePosition: { x: 495, y: 130 },
    },
    {
      text: "Term: " + record.term,
      absolutePosition: { x: 20, y: 160 },
    },
    {
      text: "Class: " + record?.current_class,
      absolutePosition: { x: 20, y: 190 },
    },
    {
      text: " ",
      absolutePosition: { x: 320, y: 160 },
    },
    {
      text: "Next Term Begins: " + record?.next_term ,
      absolutePosition: { x: 320, y: 190 },
    },
    {
      text: "Academic Year: " + record?.academic_year,
      absolutePosition: { x: 20, y: 220 },
    },
    {
      text: "Attendance: " + record?.attendance + "/" + record?.total_days,
      absolutePosition: { x: 320, y: 220 },
    },
    {
      text: "Name: " + record.name,
      absolutePosition: { x: 20, y: 130 },
    },
    {
      absolutePosition: { x: 20, y: 250 },
      table: {
        widths: ["*", "*"],
        heights: 15,
        fontSize: 8,
        body,
      },
    },
    {
      text: "GENERAL REMARKS: " + record.teachers_remarks.toUpperCase(),
      absolutePosition: { x: 20, y: 740 },
      fontSize:10
    },
    {
      text: "PROMOTED TO: " + (record?.term === "Third Term" ? getNextClass() : " "),
      absolutePosition: { x: 20, y: 760 },
      fontSize:10
    },
    {
      image: signature, // head signature...
      absolutePosition: { x: 410, y: 760 },
      fit: [100, 100],
    },
    {
      text: "...........................................\nHead Teacher's Signature",
      absolutePosition: { x: 400, y: 770 },
    },
  ];
};
