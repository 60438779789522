import { randomRange } from "../../functions";

export const remarks = [
  "You've got the potential to achieve more",
  "Excellent performance",
  "Good attempt, keep on working hard",
  "Brilliant performance",
  "Good work done, keep on working",
  "An impressive performance",
  "An improved result, keep it up",
  "Strive to attain greater heights",
  "Quite good results, keep on working hard",
  "Great performance, keep it up",
  "Well-formed performance, keep on working hard",
  "Satisfactory performance, keep on working",
  "Needs guidance from you and I",
  "Should put in more effort next time",
  "Great effort! Hard work pays off.",
  "Enthusiastic learner, keep it up!",
  "Impressive improvement – well done!",
  "Creative approach to assignments!",
  "Taking risks in learning – commendable.",
  "Excellent problem-solving skills!",
  "Proud of your dedication!",
  "Active in class discussions – great!",
  "Putting in extra effort – it shows.",
  "Attention to detail is commendable.",
  "Impressed with your analysis!",
  "Supportive classmate – thank you!",
  "Resilient in facing challenges.",
  "Taking on leadership roles – fantastic!",
  "Thoughtful questions – keep it up!",
  "Applying learning in real-world scenarios!",
  "Meeting deadlines – setting a strong example.",
  "Collaborating well with peers – valuable skill.",
  "Unique perspective adds depth – share more!",
];

export const conducts = [
  "Punctual and prepared for class.",
  "Active in discussions and activities.",
  "Strong work ethic and commitment.",
  "Respectful to peers and staff.",
  "Listens attentively and follows instructions.",
  "Completes assignments with care.",
  "Asks thoughtful questions.",
  "Collaborates well in group activities.",
  "Takes initiative in seeking challenges.",
  "Maintains a positive attitude.",
  "Manages time effectively and meets deadlines.",
  "Responsible for belongings and materials.",
  "Shows empathy and kindness.",
  "Resolves conflicts constructively.",
  "Accepts and acts on constructive feedback.",
  "Engages in extracurricular activities.",
  "Demonstrates good digital citizenship.",
  "Strives for excellence academically and beyond.",
  "Displays honesty and integrity.",
  "Embraces challenges with a growth mindset.",
  "Presents well-organized work.",
  "Respects diversity and values perspectives.",
  "Demonstrates effective communication skills.",
  "Seeks help when faced with difficulties.",
];

export const attitudes = [
  "Optimistic and positive.",
  "Respectful to peers and teachers.",
  "Enthusiastic about learning.",
  "Open to feedback and improvement.",
  "Persistent in challenges.",
  "Collaborative and supportive.",
  "Responsible for tasks.",
  "Proactive in seeking help.",
  "Appreciates diverse perspectives.",
  "Patient in learning.",
  "Adaptable to change.",
  "Courageous in expressing ideas.",
  "Demonstrates a growth mindset.",
  "Shows humility and learns from mistakes.",
  "Values teamwork and cooperation.",
  "Appreciates time management.",
  "Maintains integrity.",
  "Shows gratitude for opportunities and support.",
  "Takes ownership of growth.",
  "Acts with kindness and empathy.",
  "Remains focused in class.",
  "Demonstrates a strong work ethic.",
  "Strives for excellence.",
  "Celebrates peers' success.",
];

export const interests = [
  "Reading fiction and non-fiction.",
  "Mathematics",
  "Science",
  "Languages",
  "Arts and crafts.",
  "Social Studies",
  "R M E",
  "Music",
  "Physical and Health Education",
  "Coding and programming.",
  "Creative writing and poetry.",
  "Sports and physical activities.",
  "Exploring cultures and languages.",
  "Arts and crafts.",
  "Playing instruments and music creation.",
  "Scientific experiments and discoveries.",
  "Community service projects.",
  "Latest technology trends.",
  "Debate and public speaking.",
  "Historical events and figures.",
  "Mathematical problem-solving.",
  "Drama and theater productions.",
  "Culinary arts and cooking.",
  "Dance and choreography.",
  "Gaming and game development.",
  "Entrepreneurship and business.",
  "Current events and news.",
  "Hobbies like gardening and DIY projects.",
];

export const head_remarks = (name) => {
  const student = name
    .split(" ")
    [randomRange(0, name.split(" ").length - 1)].trim();
  return [
    `Very good performance ${student}. Soar Higher with eagles`,
    `Very much improved performance ${student}. Well done`,
    `This result is very promising ${student}, strive to attain higher grades `,
    "Marvellous work done",
    "A brilliant work done",
    `You have got the potential ${student}`,
    `An excellent work done, ${student}`,
    `Challenge yourself more ${student}, You have the potential`,
    `Splendid performance ${student} Do not rest on your oars!!`,
    `Soar higher, ${student}. The sky is your springboard!`,
    `Impressive performance, ${student}. Continue challenging yourself.`,
    `You should believe in your potential, ${student}.`,
    `Do not rest on your oars, ${student}. Keep working hard. This is a splendid performance`,
    `Well done, ${student}.`,
    `Strive to move all your B's to A's, ${student}. Good performance`,
    `You have done very well. ${student}`,
  ];
};

export const progress_remarks = (name) => {

  return [
    "Your progress has been amazing . Keep up the good work",
    "Congratulations on your achievements . Keep pushing yourself",
    "You're on track to achieve greatness . Keep pushing",
    "You're doing great . Keep up the good work",
    "You're performing exceptionally well . Keep up the good work",
    "You're doing great . Keep pushing",
    name + " has the potential to do better","An impressive performance, Well done.",
  ]
} 
