import axios from "axios";
import { decryptData } from "../functions/crypt";

// Migrating to Mongo
export const proxy =
  process.env.NODE_ENV === "production" ? "https://schools-x-server.onrender.com" : "http://localhost:4000";

// export const proxy = "https://schools-x-server.onrender.com"
export const mailerPath = process.env.NODE_ENV === "production" ? "https://schools-x-mailer.onrender.com" : "http://localhost:8000"; // ""

export const xmail = decryptData(localStorage.getItem("xmail"));
const dbName = decryptData(localStorage.getItem("DBN"));

export const uploadData = async ({ path, data }) => {
  return axios.post(
    `${proxy + path}`,
    { ...data },
    { headers: { dbName: dbName, xmail } } 
  );
};

export const getCount = async ({ path, getData }) => {
  const { data } = await axios.get(`${proxy + path}`, {
    headers: { dbName: dbName, xmail },
  });
  getData(data);
};

export const readDocuments = async ({ path, getData }) => {
  try {
    const { data } = await axios.get(`${proxy + path}`, {
      headers: { dbName: dbName, xmail },
    });
    getData(data);
  } catch (err) {
    console.log(err);
  }
};


export const readDocument = async ({ path, id, getData }) => {
  try {
    const { data } = await axios.get(`${proxy + path}/${id}`, {
      headers: { dbName: dbName, xmail:xmail },
    });
    getData(data);
  } catch (error) {
    console.log(error);
  }
};

export const updateData = async ({ path, id, data }) => {
  return axios.patch(`${proxy + path}/${id}`, data, {
    headers: { dbName: dbName, xmail:xmail  },
  });
};

export const deleteData = async ({ path, id }) => {
  await axios.delete(`${proxy + path}/${id}`, { headers: { dbName: dbName, xmail } });
};
