import axios from "axios";
import { proxy, xmail } from "../../endpoints";
import { dbName } from "../../assets/data/states";
import _ from "lodash";

export const getAttendance = async (id, term, year ) => {
    const { data } = await axios.get(
      `${proxy}/attendance/history/${id}?term=${term}&academic_year=${year}`,
      {
        headers: {
          dbName,
          xmail
        },
      }
    );

    return _.filter(data, (dt) => dt.is_present === true).length || 1;
  };