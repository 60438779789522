import { HiCheckCircle } from "react-icons/hi";
import { BiSolidMessageRoundedError } from "react-icons/bi";
import { RiCloseCircleFill } from "react-icons/ri";

export const Feedback = ({ type, message, setOpen }) => {
  setTimeout(setOpen, 5000)
  return (
  <div
    className={`fixed bottom-2 left-2 rounded-md shadow-md z-[5000] ${
      type === "success" ? "bg-green-500" : "bg-red-500"
    } flex items-center slide-up p-3 w-72`}
  >
    <div className="flex items-center space-x-2 p-1">
      {type === "success" ? (
        <HiCheckCircle className="text-2xl text-white" />
      ) : (
        <BiSolidMessageRoundedError className="text-2xl text-white" />
      )}
      <div className="text-white">
        <p className="text-xs">{message}</p>
      </div>
    </div>
    <button className="absolute bg-red-500 rounded-full -top-2 -right-1" onClick={() => setOpen()}>
      <RiCloseCircleFill className="text-white text-lg" />
    </button>
  </div>
)
} 
