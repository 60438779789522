import { LuClipboardEdit } from "react-icons/lu";
import { GoPlus } from "react-icons/go";
import TableModal from "../components/modals/table";
import { useEffect, useState } from "react";
import { days } from "../assets/data/time";
import { proxy, xmail } from "../endpoints";
import axios from "axios";
import _ from "lodash";
import { AnimatePresence, motion  } from "framer-motion";
import { dbName } from "../assets/data/states";

export default function TimeTable({ profile, settings }) {
  const [show, setShow] = useState(false);
  const [day, setDay] = useState(days[new Date().getDay() - 1]);
  const today = new Date().toLocaleDateString("en-GB", { weekday: "long" });
  const [table, setTable] = useState(null);

  useEffect(() => {
    async function readData() {
      const { data } = await axios.get(
        `${proxy}/table/get-class/${profile.class_assigned}/${profile.division}?term=${settings.currentTerm}`,
        {
          headers: {
            dbName,
            xmail
          },
        }
      );
      setTable(data);
    }
    readData();
  }, [profile.class_assigned, settings.currentTerm, profile.division]);

  const isActive = (day, hour) => {
    return day === today && 1 + (new Date().getHours() % 12) === hour;
  };

  const subjects = (dy) => {
    return table?.timers?.find((tm) => tm.day === day);
  };

  const getLessons = (day) => {
    let info = _.find(table?.timers || [], (td) => td.day === day);
    return (
      <>
        <td
          className={`p-3 border ${
            isActive(day, info?.lesson_1?.start) ? "bg-green-500" : "bg-white"
          } truncate`}
        >
          {info !== undefined ? info.lesson_1?.subject : ""}
        </td>
        <td
          className={`p-3 text-md border ${
            isActive(day, info?.lesson_2?.start) ? "bg-green-500" : "bg-white"
          } truncate`}
        >
          {info !== undefined ? info.lesson_2?.subject : ""}
        </td>
        <td
          className={`p-3 border ${
            isActive(day, info?.lesson_3?.start) ? "bg-green-500" : "bg-white"
          } truncate`}
        >
          {info !== undefined ? info.lesson_3?.subject : ""}
        </td>
        <td
          className={`p-3 border ${
            isActive(day, info?.lesson_4?.start) ? "bg-green-500" : "bg-white"
          } truncate`}
        >
          {info !== undefined ? info.lesson_4?.subject : ""}
        </td>
        <td
          className={`p-3 border ${
            isActive(day, info?.lesson_5?.start) ? "bg-green-500" : "bg-white"
          } truncate`}
        >
          {info !== undefined ? info.lesson_5?.subject : ""}
        </td>
        <td
          className={`p-3 border ${
            isActive(day, info?.lesson_6?.start) ? "bg-green-500" : "bg-white"
          } truncate`}
        >
          {info !== undefined ? info.lesson_6?.subject : ""}
        </td>
      </>
    );
  };

  return (
    <div className="flex-1 p-4 h-screen relative">
      <div className="p-2 -space-y-1">
        <h3 className="text-lg">
          Time Table for {profile.class_assigned + profile?.division}
        </h3>
        <p className="text-gray-500 text-xs ">{settings.currentTerm}</p>
      </div>
      <table className="hidden md:table table-auto w-full bg-white text-xs overflow-hidden text-sm">
        <thead>
          <tr className="font-medium truncate font-bold">
            <td className="p-3 border text-center">Day</td>
            <td className="p-3 border text-center">8:00 - 9:00</td>
            <td className="p-3 border text-center">9:00 - 10:00</td>
            <td className="p-3 border text-center">10:30 - 11:30</td>
            <td className="p-3 border text-center">11:30 - 12:30</td>
            <td className="p-3 border text-center">1:30 - 2:30</td>
            <td className="p-3 border text-center">2:30 - 3:30</td>
            <td className="p-3 border text-center"></td>
          </tr>
        </thead>
        <tbody>
          {days.map((dy, idx) => (
            <tr key={idx} className="font-light">
              <td className="p-3 border  truncate">{dy}</td>
              {getLessons(dy)}
              <td className="p-3 text-left truncate">
                <button
                  onClick={() => {
                    setDay(dy);
                    setShow(!show);
                  }}
                >
                  <LuClipboardEdit className="text-xl text-gray-500" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex flex-col justify-center gap-2  md:hidden">
        {days.map((dy) => (
          <>
            <div
              key={dy}
              className={`w-full h-12 ${
                day === dy ? "border-x-2 border-x-green-500 rounded-md" : ""
              } items-center flex justify-between bg-gray-200 shadow-md p-2`}
            >
              <p>{dy}</p>
              <div className="flex justify-around">
                <button onClick={() => setDay(day === dy ? "" : dy)}>
                  <GoPlus className="text-gray-600 text-2xl" />
                </button>
                <button
                  onClick={() => {
                    setDay(dy);
                    setShow(!show);
                  }}
                >
                  <LuClipboardEdit className="text-xl text-gray-500" />
                </button>
              </div>
            </div>
            <table className={`${day === dy ? "table-auto" : "hidden"} mt-2`}>
              <thead className="border">
                <tr className="font-medium border-b border-b-gray-400">
                  <td className="p-2 ">Session</td>
                  <td className="p-2 ">Subjects</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2 text-sm font-semibold border">Lesson 1</td>
                  <td className="p-2 text-sm border truncate">
                    {subjects(dy)?.lesson_1?.subject}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-sm font-semibold border">Lesson 2</td>
                  <td className="p-2 text-sm border truncate">
                    {subjects(dy)?.lesson_2?.subject}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-sm font-semibold border">Lesson 3</td>
                  <td className="p-2 text-sm border truncate">
                    {subjects(dy)?.lesson_3?.subject}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-sm font-semibold border">Lesson 4</td>
                  <td className="p-2 text-sm border truncate">
                    {subjects(dy)?.lesson_4?.subject}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-sm font-semibold border">Lesson 5</td>
                  <td className="p-2 text-sm border truncate">
                    {subjects(dy)?.lesson_5?.subject}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-sm font-semibold border">Lesson 6</td>
                  <td className="p-2 text-sm border truncate">
                    {subjects(dy)?.lesson_6?.subject}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ))}
      </div>

      <AnimatePresence>
        {show && (
          <motion.div
            initial={{ x: 100 }}
            animate={{ x: 0 }}
            exit={{ x: 100 }}
            className="flex flex-col md:flex-row fixed  top-0 w-screen h-screen"
          >
            <div onClick={() => setShow(false)} className="w-full md:w-1/2 bg-gray-50/30 backdrop-blur-sm h-1/3 md:h-screen"></div>
            <div className="flex-1 bg-white">
              <TableModal day={day} profile={profile} settings={settings} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
