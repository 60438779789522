import { decryptData } from "../../functions/crypt";

export const INITIAL_EXAMS_STATE = {
    mcs: 0,
    mes: 0,
    mts: 0,
    mgr: "F",
    mrm: "Buck Up",

    ecs: 0,
    ees: 0,
    ets: 0,
    egr: "F",
    erm: "Buck Up",

    cracs: 0,
    craes: 0,
    crats: 0,
    cragr: "F",
    crarm: "Buck Up",

    fcs: 0,
    fes: 0,
    fts: 0,
    fgr: "F",
    frm: "Buck Up",

    fncs: 0,
    fnes: 0,
    fnts: 0,
    fngr: "F",
    fnrm: "Buck Up",

    scs: 0,
    ses: 0,
    sts: 0,
    sgr: "F",
    srm: "Buck Up",

    owcs: 0,
    owes: 0,
    owts: 0,
    owgr: "F",
    owrm: "Buck Up",

    rmcs: 0,
    rmes: 0,
    rmts: 0,
    rmgr: "F",
    rmrm: "Buck Up",

    hcs: 0,
    hes: 0,
    hts: 0,
    hgr: "F",
    hrm: "Buck Up",

    ictcs: 0,
    ictes: 0,
    ictts: 0,
    ictgr: "F",
    ictrm: "Buck Up",

    interest: "",
    remarks: "",
    attitude: "",
    conduct: "",
    ht_remarks: "",

    overrall_aggregate: 0,
}

export const dbName = decryptData(localStorage.getItem("DBN"));
export const senderID = decryptData(localStorage.getItem("sID"));