import { RxDashboard } from "react-icons/rx";
import { PiNotePencilThin } from "react-icons/pi";
import { LiaBookSolid, LiaSchoolSolid } from "react-icons/lia";
import { AiOutlineBorderlessTable } from "react-icons/ai";
import { HiOutlineUserGroup, HiOutlineFolderOpen } from "react-icons/hi";
import { BsPersonCheck } from "react-icons/bs";

export const routes = (toggle) => [
  {
    path: "/",
    Icon: RxDashboard,
    title: "Dashboard",
    toggle: toggle,
  },
  {
    path: "/students",
    Icon: HiOutlineUserGroup,
    title: "Students",
    toggle: toggle,
  },
  {
    path: "/attendance",
    Icon: BsPersonCheck,
    title: "Attendance",
    toggle: toggle,
  },
  {
    path: "/time-table",
    Icon: AiOutlineBorderlessTable,
    title: "Time Table",
    toggle: toggle,
  },
  {
    path: "/mid-term",
    Icon: LiaBookSolid,
    title: "Midterm",
    toggle: toggle,
  },
  {
    path: "/homeworks",
    Icon: LiaSchoolSolid,
    title: "Homeworks",
    toggle: toggle,
  },
  {
    path: "/examination",
    Icon: PiNotePencilThin,
    title: "Exams",
    toggle: toggle,
  },
  {
    path: "/exam-reports",
    Icon: HiOutlineFolderOpen,
    title: "Reports",
    toggle: toggle,
  },
];
