
import CryptoJS from "crypto-js";


const encryptKey = 'x-constants'

export const encryptData = (text) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    encryptKey
  ).toString();

  return data;
};

export const decryptData = (text) => {
  try {
    const bytes = CryptoJS.AES.decrypt(text, encryptKey);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  } catch (error) {
    return null;
  }
};

export const formatMail = (id) => {
  const addr = id.replaceAll("-",".")

  return addr+"@lschoolx.com".toLowerCase()
}
